@import '../../styles/variables';

.form-layout {
  max-width: 480px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background: #ffffff;
  margin: 0 auto;
  &.sm {
    width: calc(100% - 24px);
    padding: 16px;
  }
}

.header {
  text-align: center;
  margin-bottom: 32px;

  h3 {
    margin-bottom: 12px;
    font-size: 24px;
    @media (min-width: 768px) {
      font-size: 30px;
    }
  }
  span {
    @media (max-width: 767px) {
      color: $contentSecondary;
    }
  }
}
.extra {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}
