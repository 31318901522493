import { message } from "antd";
import Konva from "konva";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Arrow,
  Ellipse,
  Image as KonvaImage,
  Layer,
  Rect,
  Stage,
} from "react-konva";
import SherlockSpinner from "../../../common/components/sherlock-spinner";
import usePreparedUrl from "../../../common/hooks/usePreparedUrl";
import { ViewerProp } from "../../common/viewer-prop";
import AppContext from "../../context/app-context";
import localStorageManager from "../../libs/local-storage-manager";
import { Annotation } from "../../types/annotation";
import { AnnotationType } from "../../types/annotation-type";
import TextAnnotation from "../screenshot-editor/text-annotation";
import * as styles from "./screenshot.module.scss";

type ScreenshotViewerProp = ViewerProp & {
  onImageLoaded?: (img: HTMLImageElement) => void;
};

/* eslint-disable-next-line */

export function Screenshot(props: ScreenshotViewerProp) {
  const { user } = useContext(AppContext);
  const [imgSrc] = usePreparedUrl(
    localStorageManager,
    props.item.screenshot_url
  );
  const annotations = Array.isArray(
    (props?.item as any)?.screenshot_annotations
  )
    ? (props?.item as any)?.screenshot_annotations
    : [];
  const [selectedAnnotation, setSelectedAnnotation] =
    useState<Annotation["id"]>();
  const [img, setImg] = useState<HTMLImageElement>();
  const [loading, setLoading] = useState(false);
  const stageRef = useRef<Konva.Stage>();
  const imageRef = useRef<Konva.Image>();
  const wrapperRef = useRef<HTMLDivElement>();
  const [zoomLevel, setZoomLevel] = useState(100);
  const multiplier = useMemo(() => zoomLevel / 100, [zoomLevel]);

  useEffect(() => {
    let mounted = true;
    const minZoomLevel = 1;
    const maxZoomLevel = 200;
    if (imgSrc) {
      setImg(undefined);
      setLoading(true);
      const img = new window.Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        if (mounted) {
          const wrapperRect = wrapperRef.current?.getBoundingClientRect();
          const wrapperWidth = wrapperRect?.width || 0;
          const imgWidth = img.naturalWidth;

          const wrapperHeight = wrapperRect?.height || 0;
          const imgHeight = img.naturalHeight;
          const scale =
            Math.min(wrapperWidth / imgWidth, wrapperHeight / imgHeight) * 100;
          setZoomLevel(Math.floor(scale));
          setImg(img);
          props.onImageLoaded?.(img);
        }
        setLoading(false);
      };
      img.onerror = () => {
        setLoading(false);
        message.error("Failed to load image");
      };
      img.src = imgSrc;
    }
    const onMouseWheelWithCtrl = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        const delta = e.deltaY / 100;

        setZoomLevel((zoomLevel) =>
          Math.floor(
            Math.max(minZoomLevel, Math.min(maxZoomLevel, zoomLevel + delta))
          )
        );
      }
    };
    wrapperRef.current?.addEventListener("wheel", onMouseWheelWithCtrl);
    return () => {
      wrapperRef.current?.removeEventListener("wheel", onMouseWheelWithCtrl);
      mounted = false;
    };
  }, [imgSrc]);

  const getShapeAttr = useCallback(
    (annotation: Annotation): Konva.ShapeConfig => {
      const attr = annotation?.["attr"];
      return {
        ...attr,
        points: [
          0,
          0,
          (attr?.x2 ?? 0) * multiplier,
          (attr?.y2 ?? 0) * multiplier,
        ],
        radiusX: (attr?.radiusX ?? 0) * multiplier,
        radiusY: (attr?.radiusY ?? 0) * multiplier,
        x: (attr?.x ?? 0) * multiplier,
        y: (attr?.y ?? 0) * multiplier,
        width: (attr?.width ?? 0) * multiplier,
        height: (attr?.height ?? 0) * multiplier,
        stroke: attr?.color,
        strokeWidth: 5,
        fill:
          annotation?.type === AnnotationType.HIGHLIGHT
            ? attr?.color
            : "transparent",
        opacity: annotation?.type === AnnotationType.HIGHLIGHT ? 0.6 : 1,
      };
    },
    [multiplier]
  );

  const selectItem = useCallback(
    (ev?: Konva.KonvaEventObject<Event>, id?: number) => {
      setSelectedAnnotation(id);
    },
    []
  );

  return (
    <div className={styles.canvas} ref={wrapperRef as any}>
      {loading && <SherlockSpinner />}
      {img && (
        <Stage
          width={img.naturalWidth * multiplier}
          height={img.naturalHeight * multiplier}
          ref={stageRef as any}
          draggable={false}
        >
          <Layer>
            <KonvaImage
              ref={imageRef as any}
              x={0}
              y={0}
              image={img}
              scale={{ x: multiplier, y: multiplier }}
            />
            {annotations.map((annotation: Annotation) => {
              const attr = getShapeAttr(annotation) as any;
              if (annotation.type === AnnotationType.ARROW) {
                return (
                  <Arrow
                    {...attr}
                    key={annotation.id}
                    draggable
                    onClick={(e) => selectItem(e, annotation.id)}
                    onTap={(e) => selectItem(e, annotation.id)}
                  />
                );
              }
              if (annotation.type === AnnotationType.ELLIPSE) {
                return (
                  <Ellipse
                    {...attr}
                    key={annotation.id}
                    draggable
                    onClick={(e) => selectItem(e, annotation.id)}
                    onTap={(e) => selectItem(e, annotation.id)}
                  />
                );
              }
              if (
                annotation.type === AnnotationType.RECTANGLE ||
                annotation.type === AnnotationType.HIGHLIGHT
              ) {
                return (
                  <Rect
                    {...attr}
                    key={annotation.id}
                    draggable
                    onClick={(e) => selectItem(e, annotation.id)}
                    onTap={(e) => selectItem(e, annotation.id)}
                  />
                );
              }
              if (annotation.type === AnnotationType.TEXT) {
                return (
                  <TextAnnotation
                    key={annotation.id}
                    annotation={annotation}
                    onChange={() => {}}
                    onDelete={() => {}}
                    onClick={() => setSelectedAnnotation(annotation.id)}
                    onClose={() => setSelectedAnnotation(undefined)}
                    active={selectedAnnotation === annotation.id}
                    user={user!}
                    multiplier={multiplier}
                    readonly={true}
                  />
                );
              }
              return null;
            })}
          </Layer>
        </Stage>
      )}
    </div>
  );
}

export default Screenshot;
