@import '../styles/variables';

.layout {
  background: $websiteBg;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
}

.content {
  display: flex;
  flex-direction: column;
}