import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { LighteningBoltIcon } from "../../../common/icons";
import * as styles from "./logo.module.scss";

interface LogoProps {
  url: string;
  theme?: "light" | "dark";
  className?: string;
}

const Logo: FunctionComponent<LogoProps> = ({ url, theme, className }) => {
  return (
    <Link
      to={url}
      className={`${styles.logo} ${theme ?? "light"} ${className ?? ""}`}
    >
      <LighteningBoltIcon />
      Sherlock <small>(Beta)</small>
    </Link>
  );
};

export default Logo;
