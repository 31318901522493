import CheckOutlined from "@ant-design/icons/CheckOutlined";
import DeleteFilled from "@ant-design/icons/DeleteFilled";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import SendOutlined from "@ant-design/icons/SendOutlined";
import { User } from "@prisma/client";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import {
  Avatar,
  Button,
  Form,
  Input,
  List,
  message,
  Space,
  Typography,
} from "antd";
import { KonvaEventObject } from "konva/lib/Node";
import { Fragment, FunctionComponent, useCallback, useState } from "react";
import { Circle, Text } from "react-konva";
import { Html } from "react-konva-utils";
import usePreparedUrl from "../../../common/hooks/usePreparedUrl";
import dayjs from "../../../common/lib/dayjs";
import { getNumericId } from "../../../common/lib/getNumericId";
import localStorageManager from "../../libs/local-storage-manager";
import { Annotation } from "../../types/annotation";
import { AnnotationText } from "../../types/annotation-text";
import ShapeColorPicker from "./shape-color-picker";
import * as styles from "./text-annotation.module.scss";

interface TextAnnotationProps {
  annotation: Annotation;
  onChange: (attr: Partial<Annotation["attr"]>) => void;
  onDelete: () => void;
  active: boolean;
  onClick: () => void;
  onClose: () => void;
  user: User;
  multiplier: number;
  readonly?: boolean;
}

const threeDigitHexToSixDigitHex = (hex: string) => {
  if (hex.startsWith("#")) {
    hex = hex.substring(1);
  }
  if (hex.length < 6) {
    const hexArray = hex.split("");
    return `#${hexArray[0]}${hexArray[0]}${hexArray[1]}${hexArray[1]}${hexArray[2]}${hexArray[2]}`;
  }
  return `#${hex}`;
};

const getForegroundColorBasedOnBackground = (hexColor: string) => {
  hexColor = threeDigitHexToSixDigitHex(hexColor);
  const rgb = hexColor.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
  const r = parseInt(rgb![1], 16);
  const g = parseInt(rgb![2], 16);
  const b = parseInt(rgb![3], 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000000" : "#ffffff";
};

const darkenColor = (hexColor: string, amount: number) => {
  hexColor = threeDigitHexToSixDigitHex(hexColor);
  const rgb = hexColor.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
  const r = parseInt(rgb![1], 16);
  const g = parseInt(rgb![2], 16);
  const b = parseInt(rgb![3], 16);
  const red = Math.max(0, Math.min(255, r - amount));
  const green = Math.max(0, Math.min(255, g - amount));
  const blue = Math.max(0, Math.min(255, b - amount));
  return `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;
};

const radius = 15;

const TextAnnotation: FunctionComponent<TextAnnotationProps> = ({
  annotation,
  onChange,
  onDelete,
  active,
  onClick,
  onClose,
  user,
  multiplier,
  readonly,
}) => {
  const [form] = Form.useForm();
  const [mouseOver, setMouseOver] = useState(false);
  const [profileUrl] = usePreparedUrl(
    localStorageManager,
    user.profile_thumbnail_url
  );
  const color = !mouseOver
    ? annotation.attr.color
    : darkenColor(annotation.attr.color, -30);
  const onDrag = (e: KonvaEventObject<Event>, addRadius?: boolean) => {
    const position = e.target.getAbsolutePosition();
    if (multiplier) {
      position.x = position.x / multiplier;
      position.y = position.y / multiplier;
    }
    const x = position.x + (addRadius ? radius : 0);
    const y = position.y + (addRadius ? radius : 0);
    onChange({
      x,
      y,
    });
  };
  const [selectedText, setSelectedText] = useState<AnnotationText>();
  const onTextInput = (values: any) => {
    const payload: AnnotationText = {
      ...values,
      id: getNumericId(),
      addedBy: `${user.name}(${user.email})`,
      addedOn: dayjs().utc().format(),
    };
    if (!payload.text) {
      message.error("Please enter some text");
      return;
    }
    onChange({
      texts: [...(annotation.attr.texts || []), payload],
    });
    form.resetFields();
  };
  const finishEditing = useCallback(() => {
    if (!selectedText?.text) {
      message.error("Please enter some text");
      return;
    }
    onChange({
      texts: annotation.attr.texts!.map((text) => {
        if (text.id === selectedText?.id) {
          return selectedText;
        }
        return text;
      }),
    });
    setSelectedText(undefined);
  }, [selectedText, annotation]);
  const deleteText = (id: any) => {
    onChange({
      texts: annotation.attr.texts!.filter((text) => text.id !== id),
    });
  };
  return (
    <Fragment>
      {active && (
        <Html>
          <div
            className={styles.wrapper}
            style={{
              left: Math.max(annotation.attr.x * multiplier, 160),
              top: annotation.attr.y * multiplier,
            }}
          >
            <div className={styles.toolbar}>
              {!readonly && (
                <ShapeColorPicker
                  color={annotation.attr.color}
                  onChange={(color) => onChange({ color })}
                />
              )}

              {!readonly && (
                <span className={styles.delete} onClick={onDelete}>
                  <DeleteFilled />
                </span>
              )}
              <span className={styles.close} onClick={onClose}>
                <FaTimes />
              </span>
            </div>
            <List
              className={styles.list}
              itemLayout="horizontal"
              dataSource={annotation?.attr?.texts || []}
              renderItem={(item) => (
                <List.Item
                  actions={
                    readonly
                      ? []
                      : [
                          <Button
                            size="small"
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => deleteText(item.id)}
                          />,
                          selectedText?.id === item.id ? (
                            <Button
                              onClick={() => finishEditing()}
                              size="small"
                              type="text"
                              icon={<CheckOutlined />}
                            />
                          ) : (
                            <Button
                              onClick={() => setSelectedText(item)}
                              size="small"
                              type="text"
                              icon={<EditOutlined />}
                            />
                          ),
                        ]
                  }
                >
                  <List.Item.Meta
                    avatar={<Avatar shape="circle">{item.addedBy[0]}</Avatar>}
                    title={item.addedBy}
                    description={
                      selectedText?.id === item.id ? (
                        <Space direction="vertical">
                          <Input
                            value={selectedText.text}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                finishEditing();
                              }
                            }}
                            onChange={(e) => {
                              setSelectedText({
                                ...selectedText,
                                text: e.target.value,
                              });
                            }}
                          />
                        </Space>
                      ) : (
                        <Space direction="vertical">
                          <Typography.Text>{item.text}</Typography.Text>
                          <Typography.Text type="secondary">
                            {dayjs(item.addedOn).fromNow()}
                          </Typography.Text>
                        </Space>
                      )
                    }
                  />
                </List.Item>
              )}
            />
            {!readonly && (
              <div className={styles.footer}>
                <Space align="start">
                  <Avatar shape="circle" src={profileUrl}>
                    {user.profile_thumbnail_url ? null : user.name[0]}
                  </Avatar>
                  <Form
                    layout="inline"
                    className={styles.form}
                    form={form}
                    initialValues={{
                      text: "",
                    }}
                    onFinish={onTextInput}
                  >
                    <Form.Item
                      name="text"
                      rules={[
                        { required: true, message: "Please input text!" },
                      ]}
                    >
                      <Input
                        placeholder="Your message..."
                        className={styles.input}
                        suffix={<SendOutlined onClick={() => form.submit()} />}
                      />
                    </Form.Item>
                  </Form>
                </Space>
              </div>
            )}
          </div>
        </Html>
      )}
      <Circle
        x={annotation.attr.x * multiplier}
        y={annotation.attr.y * multiplier}
        radius={radius}
        fill={color}
        shadowEnabled
        shadowBlur={5}
        shadowColor={color}
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
        onClick={onClick}
        draggable
        onDragMove={(e) => onDrag(e)}
      />
      <Text
        x={annotation.attr.x * multiplier - radius}
        y={annotation.attr.y * multiplier - radius}
        width={30}
        height={30}
        verticalAlign="middle"
        align="center"
        text={(annotation.attr.texts?.length || 0).toString()}
        fill={getForegroundColorBasedOnBackground(annotation.attr.color)}
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
        onClick={onClick}
        draggable
        onDragMove={(e) => onDrag(e, true)}
      />
    </Fragment>
  );
};

export default TextAnnotation;
