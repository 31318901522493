import jwtDecode from "jwt-decode";
import dayjs from "../../common/lib/dayjs";
import { LocalStorageManagerBase } from "../../common/types/local-storage-manager-base";

class LocalStorageManager extends LocalStorageManagerBase {
  accessTokenKey = "access_token";
  refreshTokenKey = "refresh_token";

  async getTokens() {
    let access_token = localStorage.getItem(this.accessTokenKey) || undefined;
    let refresh_token = localStorage.getItem(this.refreshTokenKey) || undefined;
    const now = dayjs.utc().unix();
    if (access_token) {
      const { exp }: any = jwtDecode(access_token);
      try {
        access_token = now >= exp ? undefined : access_token;
      } catch (e) {
        access_token = undefined;
      }
    }
    if (refresh_token) {
      const { exp }: any = jwtDecode(refresh_token);
      try {
        refresh_token = now >= exp ? undefined : refresh_token;
      } catch (e) {
        refresh_token = undefined;
      }
    }
    if (access_token && refresh_token) {
      return { access_token, refresh_token };
    }
    return null;
  }
  async saveTokens(accessToken?: string, refreshToken?: string) {
    if (accessToken) {
      localStorage.setItem(this.accessTokenKey, accessToken);
    }
    if (refreshToken) {
      localStorage.setItem(this.refreshTokenKey, refreshToken);
    }
  }
  async clearTokens() {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
  }
}

export default new LocalStorageManager();
