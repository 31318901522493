import { stringify } from "query-string";
import { replaceParams } from "./replaceParams";

export function toUrl(
  url: string,
  params: { [key: string]: string | number | boolean },
  queryParams?: { [key: string]: string | number | boolean }
) {
  let path = replaceParams(url, params);
  if (queryParams) {
    path += "?" + stringify(queryParams);
  }
  return path;
}

export function toUrlWithReferrer(
  url: string,
  params: { [key: string]: string | number | boolean },
  queryParams?: { [key: string]: string | number | boolean }
) {
  const referrer = location.href.substring(location.origin.length);
  return toUrl(url, params, { ...queryParams, referrer });
}
