@import '../../styles/variables';

.detail {
    display: flex;
    flex-direction: row;
    height: 100%;
    background: $purple1;
    height: calc(100vh - 64px);
}

.content {
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;

    padding-left: 25px;
    padding-right: 25px;

}

.content-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    height: 50px;
}

.content-display {
    position: relative;
    height: calc(100% - 85px);
}

.content-info {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 60px;
}


.info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.info-title-area {
    display: inline-flex;
    flex-direction: column;
}

.title {
    font-size: $fontSizeMedium18;
    color: $contentPrimary;
    font-family: $fontFamilyInter;
    margin-bottom: 0 !important;
}

.name,
.date,
.location {
    font-size: $fontSizeMedium14;
}

.name {
    color: $contentPrimary;
}

.date {
    color: $contentSecondary;

    // add dot before
    &:before {
        content: '•';
        margin-right: 5px;
    }
}

.location {
    color: $linkColor;

    // add dot before
    &:before {
        content: 'in';
        margin-right: 5px;
        color: $contentSecondary  !important;
    }
}

.btn {
    padding: 0;
    color: $contentPrimary;
    min-width: 40px;
}

.spinner-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: $bgSurface;
}