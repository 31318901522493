@import '../../styles/variables';

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 44px 0px;

    @media (max-width: $brPoint3) {
        padding: 32px 0px;
    }

    @media (max-width: $brPoint4) {
        padding: 16px 0px;
    }



    /* global/darkblue/darkblue10 */

    background: #0A0127;

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px;
        gap: 32px;
        width: 100%;
        max-width: 1216px;

        @media(max-width: $brPoint1) {
            max-width: 100%;
            padding: 0px 24px;
        }

        @media(max-width: $brPoint3) {
            padding: 0 16px;
        }

        @media(max-width: $brPoint4) {
            flex-direction: column;
            align-items: center;
            gap: 16px;
        }

        p {
            /* Text md/Normal */

            font-family: $fontFamilyInter;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            /* identical to box height, or 150% */


            /* Content/ContrastSecondary */

            color: rgba(255, 255, 255, 0.56);



        }

        .links {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 24px;

            a {
                color: rgba(255, 255, 255, 0.56);

                &:hover {
                    color: #fff;
                }
            }
        }

    }
}