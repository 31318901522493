import { useEffect, useRef, useState } from "react";
import { DefaultQuoteBgColor } from "../../../common/constants";
import { getTextColor } from "../../../common/lib/getTextColor";
import { ViewerProp } from "../../common/viewer-prop";
import * as styles from "./quote.module.scss";
const minFontSize = 12;
const maxFontSize = 48;

export function Quote(props: ViewerProp) {
  const wrapperRef = useRef<HTMLDivElement>();
  const shadowTextRef = useRef<HTMLSpanElement>();
  const [fontSize, setFontSize] = useState(maxFontSize);
  const item = props.item;
  const bgColor = item.background ?? DefaultQuoteBgColor;
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    let currentFontSizeNum = maxFontSize;
    shadowTextRef.current!.style.fontSize = `${currentFontSizeNum}px`;
    const checkOverflow = () => {
      const wrapperHeight =
        wrapperRef?.current?.getBoundingClientRect()?.height ?? 0;
      const shadowHeight =
        shadowTextRef?.current?.getBoundingClientRect()?.height ?? 0;
      return shadowHeight > wrapperHeight;
    };
    wrapperRef?.current?.classList?.remove("overflow");

    while (1) {
      const overflown = checkOverflow();
      if (overflown && currentFontSizeNum > minFontSize) {
        currentFontSizeNum -= 4;
        shadowTextRef.current!.style.fontSize = `${currentFontSizeNum}px`;
      } else {
        if (overflown) {
          wrapperRef.current!.classList.add("overflow");
        }
        setFontSize(currentFontSizeNum);
        break;
      }
    }
    return () => {};
  }, [item?.text, screenSize]);
  useEffect(() => {
    let timeout: any;
    const onResize = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setScreenSize(window.innerWidth);
      }, 500);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);
  return (
    <div className={styles.canvas}>
      <div
        className={styles["text-area"]}
        ref={wrapperRef as any}
        style={{
          backgroundColor: bgColor!,
        }}
      >
        <div
          className={styles["text"]}
          style={{
            fontSize: `${fontSize}px`,
            color: getTextColor(bgColor),
          }}
          dangerouslySetInnerHTML={{ __html: item?.text || "" }}
        />
        <div
          className={styles["shadow-text"]}
          style={{
            fontSize: `${fontSize}px`,
          }}
          ref={shadowTextRef as any}
          dangerouslySetInnerHTML={{ __html: item?.text || "" }}
        />
      </div>
    </div>
  );
}

export default Quote;
