@import '../../styles/variables';

.btn {
    padding: 10px 16px;
    border-radius: $borderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;

    svg {
        margin-right: 8px;
    }
}