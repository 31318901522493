import { message } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ResponseWithSuccess } from "../../../common/interfaces/ResponseWithSuccess";
import { buildErrorMessageFromError } from "../../../common/lib/buildErrorMessageFromError";
import httpClient from "../../libs/http-client";
import GoogleBtn from "./google-btn";
interface GoogleSignInBtnProps {}

const GoogleSignInBtn: FunctionComponent<GoogleSignInBtnProps> = () => {
  const [busy, setBusy] = useState(false);
  const [queryParams, setQueryParams] = useSearchParams();
  const onClick = async () => {
    setBusy(true);
    try {
      const redirectTo = queryParams.get("redirectTo");
      let url = "auth/social-login/google/url";
      if (redirectTo) {
        url += `?redirectTo=${encodeURIComponent(redirectTo)}`;
      }
      const { data } = await httpClient.get<ResponseWithSuccess>(url);

      if (typeof data === "string") {
        if (window.insideExtension) {
          window.open(data);
        } else {
          window.location.href = data;
        }
      } else {
        throw new Error("Invalid response");
      }
    } catch (error) {
      console.error(error);
      message.error(buildErrorMessageFromError(error));
    }
    setBusy(false);
  };
  useEffect(() => {
    const social = queryParams.get("social");
    const provider = queryParams.get("provider");
    if (social === "true" && provider === "google") {
      onClick();
    }
  }, [queryParams]);
  return (
    <GoogleBtn label="Sign in with Google" busy={busy} onClick={onClick} />
  );
};

export default GoogleSignInBtn;
