import { User } from "@prisma/client";
import { Alert, Form, Input, Typography } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ResponseWithSuccess } from "../../../common/interfaces/ResponseWithSuccess";
import { buildErrorMessageFromError } from "../../../common/lib/buildErrorMessageFromError";
import ActionBtn from "../../components/action-btn/action-btn";
import AuthFormLayout from "../../components/auth-form-layout/auth-form-layout";
import PublicPageLayout from "../../layouts/public-page-layout";
import httpClient from "../../libs/http-client";
import { SIGN_IN } from "../../route-paths";
import "./index.module.scss";

/* eslint-disable-next-line */
export interface ActiveAccountProps {}

const ActiveAccount: FunctionComponent<ActiveAccountProps> = (props) => {
  const [form] = Form.useForm();
  const [busy, setBusy] = useState(false);
  const [serverMessage, setServerMessage] = useState<string>();
  const [serverMessageType, setServerMessageType] = useState<
    "success" | "error"
  >();
  const [query] = useSearchParams();

  useEffect(() => {
    form.setFieldsValue({
      email: query.get("email"),
      activation_token: query.get("token"),
    });
  }, [query]);
  const onFinish = async (values: any) => {
    setBusy(true);
    setServerMessage(undefined);
    try {
      const { message } = await httpClient.post<ResponseWithSuccess<User>>(
        `auth/activate/${values.email}/${values.activation_token}`,
        {}
      );
      setServerMessage(message ?? "Successfully activated!");
      setServerMessageType("success");
    } catch (error) {
      setServerMessage(buildErrorMessageFromError(error));
      setServerMessageType("error");
    }
    setBusy(false);
  };
  return (
    <PublicPageLayout title="Activate Account" className="forms-content">
      <div className="form-area">
        <AuthFormLayout
          title="Active Account"
          secondaryTitle="One step away from your account"
          extraContent={
            <Typography.Text>
              Already activated? <Link to={SIGN_IN}>Sign In </Link>
            </Typography.Text>
          }
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            {serverMessage && (
              <Form.Item>
                <Alert
                  message={serverMessage}
                  type={serverMessageType}
                  showIcon
                />
              </Form.Item>
            )}
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  max: 255,
                  message: "Please enter your email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="activation_token"
              label="Activation Code"
              rules={[
                {
                  required: true,
                  min: 5,
                  message: "Please enter the code sent to your email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <ActionBtn type="primary" htmlType="submit" block busy={busy}>
                Activate Account
              </ActionBtn>
            </Form.Item>
          </Form>
        </AuthFormLayout>
      </div>
    </PublicPageLayout>
  );
};

export default ActiveAccount;
