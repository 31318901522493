export const HOMEPAGE = "/";
export const SIGN_IN = "/sign-in";
export const SIGN_IN_WITH_PROVIDER = "/sign-in/:provider";
export const SIGN_UP = "/sign-up";

export const DASHBOARD = "/dashboard";
export const PROFILE = DASHBOARD + "/profile";
export const TIMELINE = DASHBOARD + "/timeline";
export const ITEMS = DASHBOARD + "/items";
export const ITEMS_DETAIL = DASHBOARD + "/items/:id";
export const ITEMS_EDIT = DASHBOARD + "/items/:id/edit";
export const FOLDERS = DASHBOARD + "/folders";
export const FOLDERS_DETAIL = DASHBOARD + "/folders/:id";
export const SETTINGS = DASHBOARD + "/settings";

export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const ACTIVATE_ACCOUNT = "/activate-account";
export const TERMS_CONDITION = "/terms-and-conditions";
export const PRIVACY_POLICY = "/privacy-policy";
export const SHARE = "/share/:id";

export const WHATS_NEW = "/whats-new";
export const ERROR_PAGE = "/error";
export const WELCOME = "/welcome";
