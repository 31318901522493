@import "../../styles/variables";

.header {
  padding-left: 40px;
  padding-right: 40px;
  background: $darkBlue9;


  &.folded {
    padding-left: 12px;
    padding-right: 12px;
  }

  &:not(.folded) {
    :global(.ant-space-item:nth-child(2)) {
      flex: 1;
    }

    position: sticky;
    top: 0;
    z-index: 1000;

    :global(.ant-menu-horizontal) {
      justify-content: center;
    }
  }
}

.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.btn {
  border-radius: $borderRadius;
  background: $actionPrimaryNormal;
  border-color: $actionPrimaryNormal;
  height: 40px;
  padding: 0 16px;
  line-height: 40px;

  &:hover,
  &:active {
    background: $actionPrimaryHover;
    border-color: $actionPrimaryHover;
  }
}

.ul {
  background: transparent;
  border-bottom: none;
}

.li {
  padding-left: 15px !important;
  padding-right: 15px !important;
  opacity: 0.5 !important;

  &:hover {
    opacity: 1 !important;

    &::after {
      display: none !important;
    }
  }

  &:global(.ant-menu-item-selected) {
    opacity: 1 !important;

    .link {
      outline: 2px solid #92bcfc;
    }

    &::after {
      display: none !important;
    }
  }


  .link {
    font-size: $fontSizeMedium18;
    // color: #6C0AE6  !important;
    color: #fff !important;

    &:active {
      outline: 2px solid #92bcfc;
    }
  }
}

.icon {
  padding: 0;
  border: none;
  width: 32px;
  height: 32px;

  :global(path) {
    stroke: $defaultHover;
  }

  &:hover,
  &:active,
  &:focus,
  &.active {
    :global(path) {
      stroke: $defaultHover;
    }
  }
}

.drawer {
  :global(.ant-drawer-content) {
    background: linear-gradient(180deg, #0b012d 0%, #140152 100%);
  }

  :global(.ant-drawer-header) {
    background: $darkBlue9;
    padding: 12px;
    border-bottom: none;
  }

  :global(.ant-drawer-title) {
    order: 0;
  }

  :global(.ant-drawer-close path) {
    stroke: $defaultHover;
  }

  :global(.ant-drawer-close) {
    order: 1;
    margin-right: 0;
  }

  :global {
    .ant-drawer-body {
      padding: 12px;
      display: flex;
      flex-direction: column;
    }

    .ant-menu {
      flex: 1;
      border: none;
    }
  }

  .btn {
    display: block;
    font-size: $fontSizeMedium14;
  }

  .li {
    border-radius: $borderRadius;

    .link {
      font-size: $fontSizeMedium14;
      font-weight: 500;
      color: $gray8;

      &:hover {
        color: white !important;
      }
    }

    &:global(.ant-menu-item-selected),
    &:hover {
      background: $darkBlue8;
    }
  }
}

.logo {
  line-height: 40px;
}