import { User } from "@prisma/client";
import { Alert, Form, Input, Typography } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ResponseWithSuccess } from "../../../common/interfaces/ResponseWithSuccess";
import { buildErrorMessageFromError } from "../../../common/lib/buildErrorMessageFromError";
import ActionBtn from "../../components/action-btn/action-btn";
import AuthFormLayout from "../../components/auth-form-layout/auth-form-layout";
import PublicPageLayout from "../../layouts/public-page-layout";
import httpClient from "../../libs/http-client";
import { SIGN_IN } from "../../route-paths";
import * as styles from "./index.module.scss";

/* eslint-disable-next-line */
export interface ResetPasswordProps {}

const PasswordResetPage: FunctionComponent<ResetPasswordProps> = (props) => {
  const [form] = Form.useForm();
  const [busy, setBusy] = useState(false);
  const [serverMessage, setServerMessage] = useState<string>();
  const [serverMessageType, setServerMessageType] = useState<
    "success" | "error"
  >();
  const [query] = useSearchParams();

  useEffect(() => {
    form.setFieldsValue({
      email: query.get("email"),
      token: query.get("token"),
    });
  }, [query]);
  const onFinish = async (values: any) => {
    setBusy(true);
    setServerMessage(undefined);
    try {
      const { message } = await httpClient.post<ResponseWithSuccess<User>>(
        `auth/reset-password`,
        values
      );
      setServerMessage(message ?? "Successfully reset!");
      setServerMessageType("success");
    } catch (error) {
      setServerMessage(buildErrorMessageFromError(error));
      setServerMessageType("error");
    }
    setBusy(false);
  };
  return (
    <PublicPageLayout
      hideFooter
      title="Activate Account"
      className="forms-content"
    >
      <div className={styles["form-area"]}>
        <AuthFormLayout
          title="Active Account"
          secondaryTitle="One step away from your account"
          extraContent={
            <Typography.Text>
              Already have password? <Link to={SIGN_IN}>Sign In</Link>
            </Typography.Text>
          }
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            {serverMessage && (
              <Form.Item
                help={
                  serverMessageType === "success" ? (
                    <Typography.Text className={styles["help-text"]}>
                      <Link to={SIGN_IN}>Sign In</Link>
                    </Typography.Text>
                  ) : null
                }
              >
                <Alert
                  message={serverMessage}
                  type={serverMessageType}
                  showIcon
                />
              </Form.Item>
            )}
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  max: 255,
                  message: "Please enter your email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="token"
              label="Reset Code"
              rules={[
                {
                  required: true,
                  min: 5,
                  message: "Please enter the code sent to your email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="New Password"
              rules={[
                {
                  required: true,
                  min: 6,
                  max: 255,
                  message: "Please! provide the new password",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <ActionBtn type="primary" htmlType="submit" block busy={busy}>
                Reset Password
              </ActionBtn>
            </Form.Item>
          </Form>
        </AuthFormLayout>
      </div>
    </PublicPageLayout>
  );
};
// export const getServerSideProps = getServerSidePropsForPublicForms;

export default PasswordResetPage;
