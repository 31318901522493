import { Alert, Form, Input, Typography } from "antd";
import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { ResponseWithSuccess } from "../../../common/interfaces/ResponseWithSuccess";
import { buildErrorMessageFromError } from "../../../common/lib/buildErrorMessageFromError";
import ActionBtn from "../../components/action-btn/action-btn";
import AuthFormLayout from "../../components/auth-form-layout/auth-form-layout";
import PublicPageLayout from "../../layouts/public-page-layout";
import httpClient from "../../libs/http-client";
import { RESET_PASSWORD, SIGN_IN } from "../../route-paths";
import * as styles from "./index.module.scss";

/* eslint-disable-next-line */
export interface ForgotPasswordProps {}

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = (
  props: ForgotPasswordProps
) => {
  const [form] = Form.useForm();
  const [busy, setBusy] = useState(false);
  const [serverMessage, setServerMessage] = useState<string>();
  const [serverMessageType, setServerMessageType] = useState<
    "success" | "error"
  >();
  const onFinish = async (values: any) => {
    setBusy(true);
    setServerMessage(undefined);
    try {
      const data = await httpClient.post<ResponseWithSuccess>(
        `auth/forgot-password`,
        values
      );
      setServerMessage(
        data.message ??
          "An email has been sent to you with a link to reset your password."
      );
      setServerMessageType("success");
    } catch (error) {
      setServerMessage(buildErrorMessageFromError(error));

      setServerMessageType("error");
    }
    setBusy(false);
  };
  return (
    <PublicPageLayout
      hideFooter
      title="Forgot Password"
      className="forms-content"
    >
      <div className={styles["form-area"]}>
        <AuthFormLayout
          title="Forgot Password!"
          secondaryTitle="Just enter your email and we'll send you a link to reset your password."
          extraContent={
            <Typography.Text>
              Already have password? <Link to={SIGN_IN}>Sign In </Link>
            </Typography.Text>
          }
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            {serverMessage && (
              <Form.Item
                help={
                  serverMessageType === "success" ? (
                    <Typography.Text className={styles["help-text"]}>
                      <Link to={RESET_PASSWORD}>Next</Link>
                    </Typography.Text>
                  ) : null
                }
              >
                <Alert
                  message={serverMessage}
                  type={serverMessageType}
                  showIcon
                />
              </Form.Item>
            )}
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  max: 255,
                  message: "Please enter your email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <ActionBtn type="primary" htmlType="submit" block busy={busy}>
                Send Reset Link
              </ActionBtn>
            </Form.Item>
          </Form>
        </AuthFormLayout>
      </div>
    </PublicPageLayout>
  );
};

export default ForgotPassword;
