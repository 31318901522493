import { getRgbValue } from "./getRgbValue";

// get black/white color based on background color

export function getTextColor(hexColor?: string | null) {
  if (!hexColor) {
    return '#000';
  }
  const [r, g, b] = getRgbValue(hexColor);
  return r * 0.299 + g * 0.587 + b * 0.114 >= 128 ? '#000000' : '#ffffff';
}
