export type IntercomConfig = {
  app_id: string;
  name: string;
  email: string;
  created_at: number;
  user_id: string;
};

export default async function bootIntercom(config?: Partial<IntercomConfig>) {
  // @ts-ignore
  window.Intercom("shutdown"); // close any existing intercom
  // wait for 300ms to ensure intercom is closed
  await new Promise((resolve) => setTimeout(resolve, 300));
  // @ts-ignore
  window.Intercom("boot", {
    app_id: (window as any).INTERCOM_APP_ID,
    ...config,
  });
}
