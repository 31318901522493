import { useRef } from "react";
import { getTextColor } from "../../../common/lib/getTextColor";
import { ViewerProp } from "../../common/viewer-prop";
import * as styles from "./note.module.scss";
const minFontSize = 12;
const maxFontSize = 48;

export function Note(props: ViewerProp) {
  const wrapperRef = useRef<HTMLDivElement>();
  const item = props.item;

  return (
    <div className={styles.canvas}>
      <div
        className={styles["text-area"]}
        ref={wrapperRef as any}
        style={{
          backgroundColor: item.background!,
          color: getTextColor(item.background),
        }}
        dangerouslySetInnerHTML={{ __html: item?.text || "" }}
      ></div>
    </div>
  );
}

export default Note;
