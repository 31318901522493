@import '../../styles/variables';

.wrapper {
  position: absolute;
  width: 320px;
  border-radius: $borderRadius;
  background: $naturalSecondary;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, 0%);
}

.toolbar {
  width: 100%;
  height: 40px;
  border-radius: $borderRadius $borderRadius 0 0;
  background: $naturalSecondary;
  border-bottom: $borderNeutral solid 1px;
  padding: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.footer {
  width: 100%;
  border-radius: 0 0 $borderRadius $borderRadius;
  background: $naturalSecondary;
  border-top: $borderNeutral solid 1px;
  padding: 5px;
  :global(.ant-space) {
    display: flex;
    justify-content: space-evenly;
  }
  :global(.ant-form-item-has-error) {
    margin-bottom: 0;
  }
}

.delete {
  width: 30px;
  height: 30px;
  border-radius: $borderRadius;
  margin: 0 5px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: $bgNegative;
}
.close {
  width: 30px;
  height: 30px;
  border-radius: $borderRadius;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  line-height: 35px;
  font-size: 16px;
  color: $bgInfo;
}

.input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  :global(input) {
    padding: 5px !important;
  }
}

.form {
  :global(.ant-row) {
    margin-right: 0 !important;
  }
}

.list {
  :global(.ant-list-item-meta-title) {
    // show ellipsis
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  :global(.ant-list-item) {
    padding-left: 5px;
    padding-right: 5px;
  }
  :global(.ant-list-item-action) {
    margin-left: 5px;
  }
}
