import { Prisma } from "@prisma/client";
import { Avatar, message, Space, Typography } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SherlockSpinner from "../../../common/components/sherlock-spinner";
import { ResponseWithSuccess } from "../../../common/interfaces/ResponseWithSuccess";
import { buildErrorMessageFromError } from "../../../common/lib/buildErrorMessageFromError";
import dayjs from "../../../common/lib/dayjs";
import { getShortName } from "../../../common/lib/getShortName";
import { toUrl } from "../../../common/lib/toUrl";
import CollectableType from "../../../common/types/collectable-type";
import Bookmark from "../../components/bookmark/bookmark";
import Note from "../../components/note/note";
import Quote from "../../components/quote/quote";
import Screenshot from "../../components/screenshot/screenshot";
import PublicPageLayout from "../../layouts/public-page-layout";
import httpClient from "../../libs/http-client";
import { ERROR_PAGE } from "../../route-paths";
import * as styles from "./index.module.scss";

type CollectableItem = Prisma.CollectableItemGetPayload<{
  include: {
    tags: true;
    organization: true;
  };
}>;

interface SharePageProps {}

const SharePage: FunctionComponent<SharePageProps> = () => {
  const [item, setItem] = useState<CollectableItem>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setLoading(true);
      httpClient
        .get<ResponseWithSuccess<CollectableItem>>(`items/${id}/share`)
        .then((res) => {
          setItem(res.data);
        })
        .catch((e) => {
          message.error(buildErrorMessageFromError(e));
          navigate(
            toUrl(
              ERROR_PAGE,
              {},
              {
                status: e?.status || "error",
                title: e?.statusText || "Something went wrong!",
                subTitle: e?.data?.message || "Please try again later.",
              }
            )
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);
  return (
    <PublicPageLayout hideFooter title="Activate Account">
      {loading || !item ? (
        <div className={styles["spinner-area"]}>
          <SherlockSpinner />
        </div>
      ) : (
        <div className={styles.detail}>
          <div className={styles.content}>
            <div className={styles["content-display"]}>
              {item?.type === CollectableType.SCREENSHOT && (
                <Screenshot item={item} />
              )}
              {item?.type === CollectableType.QUOTE && <Quote item={item} />}
              {item?.type === CollectableType.NOTE && <Note item={item} />}
              {item?.type === CollectableType.BOOKMARK && (
                <Bookmark item={item} />
              )}
            </div>
            <div className={styles["content-info"]}>
              <div className={styles["info-row"]}>
                <div className={styles["info-title-area"]}>
                  <Typography.Title level={4} className={styles.title}>
                    {(item as any).name}
                  </Typography.Title>
                  <Space align="center">
                    <Avatar shape="circle" className={styles.avatar}>
                      {getShortName(item?.organization?.name)}
                    </Avatar>
                    <Typography.Text className={styles.name}>
                      {item?.organization?.name}
                    </Typography.Text>
                    <Typography.Text className={styles.date}>
                      {dayjs(item.created_at).utc().fromNow()}
                    </Typography.Text>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </PublicPageLayout>
  );
};

export default SharePage;
