export enum ExtensionEvents {
  RESIZE = "resize",
  PING = "ping",
  PONG = "pong",
  TOKEN_RECEIVED = "tokenReceived",
  SAVE_TOKEN = "saveToken",
  LOGOUT = "logout",
  Tick = "tick",
  ToggleExtensionFrame = "toggleExtensionFrame",
  GetExtensionState = "getExtensionState",
  GetExtensionStateResult = "getExtensionStateResult",
  RefreshUser = "refreshUser",
  RefreshUserResult = "refreshUserResult",
  BeforeScreenshot = "beforeScreenshot",
  AfterScreenshot = "afterScreenshot",
  TakeScreenshot = "takeScreenshot",
  TakeScreenshotResult = "takeScreenshotResult",
  GetBookmarkInfo = "getBookmarkInfo",
  GetBookmarkInfoResult = "getBookmarkInfoResult",
  GetSelectedText = "getSelectedText",
  GetSelectedTextResult = "getSelectedTextResult",
  ProxyRequest = "proxyRequest",
  ContentScriptReady = "contentScriptReady",
  ContentScriptReadyResult = "contentScriptReadyResult",
}
