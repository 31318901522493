import { HttpClientConfig } from "../../common/interfaces/http-client-config";
import SherlockHttpClient from "../../common/lib/sherlock-http-client";
import Manager from "./local-storage-manager";
const config: HttpClientConfig = {
  baseUrl: `${process.env.API_URL}/api`,
  localStorageManager: Manager,
  headers: {},
};

const httpClient = new SherlockHttpClient(config);
export default httpClient;
