@import '../../styles/variables';
@import '../..//styles/editor-screen.scss';

.canvas {
  background: $purple1;
}

.text-area {
  position: absolute;
  top: 50px;
  left: 50px;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  background: $bgSurface;
  z-index: 1;
  border: $borderNeutral solid 1px;
  border-radius: $borderRadius;
  overflow: auto;
  padding: 12px;

  * {
    color: inherit !important;
    font-family: $fontFamilyComicNeue;
    font-weight: 700;
  }
}
