import { parse, stringify } from "query-string";
import { useEffect, useState } from "react";
import { LocalStorageManagerBase } from "../types/local-storage-manager-base";

type ReturnType = [string | undefined | null, boolean];
export default function usePreparedUrl(
  manager: LocalStorageManagerBase,
  url?: string | null
): ReturnType {
  const [preparedUrl, setPreparedUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      let _url = url;

      if (!url) {
        _url = "";
      } else {
        const apiUrl = process.env.API_URL;
        const result = await manager.getTokens();
        const token = result?.access_token;

        // if url starts with blob: or http: or https:, return it
        if (
          url.startsWith("blob:") ||
          url.startsWith("http:") ||
          url.startsWith("https:") ||
          url.startsWith("data:")
        ) {
          _url = url;
        } else {
          // if url starts with slash, remove it
          if (url.startsWith("/")) {
            url = url.substr(1);
          }

          url = `${apiUrl}/${url}`;

          // separate query string from url
          const query = parse(url.split("?")[1] || "");
          url = url.split("?")[0];

          // if token is present, add it to the url
          if (token) {
            query.access_token = token;
          }
          // convert query object to query string
          const queryString2 = stringify(query);
          // if query string is present, add it to the url
          if (queryString2) {
            url += `?${queryString2}`;
          }
          _url = url;
        }
      }

      setPreparedUrl(_url);
    })();
  }, [url]);
  return [preparedUrl, isLoading];
}
