export { default as FingerIcon } from "jsx:./2finger.svg";
export { default as AcademicCapIcon } from "jsx:./academic-cap.svg";
export { default as ArrowRight } from "jsx:./arrow-right.svg";
export { default as BellIcon } from "jsx:./bell.svg";
export { default as BookmarkIcon } from "jsx:./bookmark.svg";
export { default as CalendarIcon } from "jsx:./calendar.svg";
export { default as CheckIcon } from "jsx:./check.svg";
export { default as ChevronDown } from "jsx:./chevron-down.svg";
export { default as ChevronLeft } from "jsx:./chevron-left.svg";
export { default as ChevronUp } from "jsx:./chevron-up.svg";
export { default as ClockIcon } from "jsx:./clock.svg";
export { default as CloseIcon } from "jsx:./close.svg";
export { default as DeleteIcon } from "jsx:./delete-bin.svg";
export { default as DribbleIcon } from "jsx:./dribble.svg";
export { default as PenIcon } from "jsx:./edit-pen.svg";
export { default as EyeIcon } from "jsx:./eye.svg";
export { default as FacebookIcon } from "jsx:./facebook.svg";
export { default as FileIcon } from "jsx:./file.svg";
export { default as FolderIcon } from "jsx:./folder.svg";
export { default as GithubIcon } from "jsx:./github.svg";
export { default as HomeIcon } from "jsx:./home.svg";
export { default as ImageIcon } from "jsx:./image.svg";
export { default as LighteningBoltIcon } from "jsx:./lightning-bolt.svg";
export { default as LinkIcon } from "jsx:./link-angled.svg";
export { default as LinkedInIcon } from "jsx:./linked-in.svg";
export { default as ListIcon } from "jsx:./list.svg";
export { default as MegaphoneIcon } from "jsx:./megaphone.svg";
export { default as NoteIcon } from "jsx:./note.svg";
export { default as PlusIcon } from "jsx:./plus.svg";
export { default as PuzzleIcon } from "jsx:./puzzle.svg";
export { default as QuoteIcon } from "jsx:./quote.svg";
export { default as QuoteCloseIcon } from "jsx:./quotes-close.svg";
export { default as SearchIcon } from "jsx:./search.svg";
export { default as SettingsIcon } from "jsx:./settings-cog.svg";
export { default as ShapeIcon } from "jsx:./shape.svg";
export { default as ShareIcon } from "jsx:./share-1.svg";
export { default as TwitterIcon } from "jsx:./twitter.svg";
export { default as UserCircleIcon } from "jsx:./user-circle.svg";
