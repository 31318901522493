import { useEffect, useState } from "react";
import prepareMessageForExtension, {
  validateMessageScope,
} from "../../common/lib/prepare-message-for-extension";
import { ExtensionEventPayload } from "../../common/types/extension-event-payload";
import { ExtensionEvents } from "../../common/types/extension-events";

export enum ExtensionStatus {
  Pinging = "Pinging",
  Installed = "Installed",
  NotInstalled = "NotInstalled",
}
export default function useExtensionStatus() {
  const [extensionStatus, setExtensionStatus] = useState<ExtensionStatus>(
    ExtensionStatus.NotInstalled
  );

  useEffect(() => {
    let isMounted = true;
    const processIncomingMessage = (request: ExtensionEventPayload) => {};

    const handleParentMessage = (event: MessageEvent) => {
      const request = event.data;
      const isValid = validateMessageScope(request);
      if (!isValid) {
        return;
      }
      processIncomingMessage(request);
    };

    const ping = () => {
      let pongReceived = false;
      setExtensionStatus(ExtensionStatus.Pinging);
      const message = prepareMessageForExtension(ExtensionEvents.PING, {
        source: "dashboard",
      });
      window.postMessage(message, "*");

      const onPong = (event: MessageEvent) => {
        const data = event.data;
        if (
          validateMessageScope(data) &&
          data.event === ExtensionEvents.PONG &&
          isMounted
        ) {
          pongReceived = true;
          setExtensionStatus(ExtensionStatus.Installed);
        }
      };
      addEventListener("message", onPong);

      setTimeout(() => {
        removeEventListener("message", onPong);
        if (!pongReceived) {
          setExtensionStatus(ExtensionStatus.NotInstalled);
        }
      }, 3000);
    };

    addEventListener("message", handleParentMessage);
    if (window?.insideExtension) {
      setExtensionStatus(ExtensionStatus.Installed);
    } else {
      ping();
    }
    return () => {
      isMounted = false;
      removeEventListener("message", handleParentMessage);
    };
  }, []);

  return {
    extensionStatus,
  };
}
