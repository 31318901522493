// split name by space and uppercase the first letter of each word


export function getShortName(name?: string | null) {
  if (!name) {
    return '';
  }
  return name
    .split(' ')
    .map((word) => word[0].toUpperCase())
    .join('');
}
