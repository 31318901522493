import Cookie from "js-cookie";
import jwtDecode from "jwt-decode";
import dayjs from "./dayjs";
const AccessTokenKey = "access_token";
const RefreshTokenKey = "refresh_token";

export namespace LocalStorageManager {
  export function getAccessToken() {
    return localStorage.getItem(AccessTokenKey);
  }
  export function getTokens() {
    let access_token = localStorage.getItem(AccessTokenKey) || undefined;
    let refresh_token = localStorage.getItem(RefreshTokenKey) || undefined;
    const now = dayjs.utc().unix();
    if (access_token) {
      const { exp }: any = jwtDecode(access_token);
      try {
        access_token = now >= exp ? undefined : access_token;
        Cookie.set("authorization", `Bearer ${access_token!}`, {
          expires: exp - now,
        });
      } catch (e) {
        access_token = undefined;
      }
    }
    if (refresh_token) {
      const { exp }: any = jwtDecode(refresh_token);
      try {
        refresh_token = now >= exp ? undefined : refresh_token;
      } catch (e) {
        refresh_token = undefined;
      }
    }

    return { access_token, refresh_token };
  }

  export function saveTokens(access_token?: string, refresh_token?: string) {
    if (access_token) {
      localStorage.setItem(AccessTokenKey, access_token);
    }
    if (refresh_token) {
      localStorage.setItem(RefreshTokenKey, refresh_token);
    }
  }

  export function clearTokens() {
    localStorage.removeItem(AccessTokenKey);
    localStorage.removeItem(RefreshTokenKey);
  }
}
