@import '../../../dashboard/styles/variables.scss';

@keyframes dash {
    from {
        stroke-dasharray: 27;
        stroke-dashoffset: 27;
    }

    to {
        stroke-dasharray: 56;
        stroke-dashoffset: 0;
    }
}

.spinner {
    gap: 0 !important;

    &.center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100vw;
    }

    >div {
        line-height: 1;
    }

    &.large {

        svg {
            transform: scale(2);
            transform-origin: top center;
        }

        span {
            margin-top: 20px;

        }
    }

    &.small {

        svg {
            transform: scale(2);
            transform-origin: top center;
        }

        span {
            margin-top: 20px;

        }
    }


    path {
        stroke: $linkColor  !important;
        stroke-dasharray: 27;
        animation: dash 1s linear infinite;
    }

    span {
        color: $linkColor;
        display: inline-block;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 14px;
    }
}