import { render } from "react-dom";
import Main from "./main";

const root = document.getElementById("root");
(() => {
  window?.parent?.postMessage({ type: "TELL_MY_LOCATION" }, "*");
  // listen to incoming messages
  window.addEventListener("message", (event) => {
    const { data } = event;
    if (data?.type === "INSIDE_EXTENSION") {
      window.insideExtension = true;
    }
  });
})();
render(<Main />, root);
