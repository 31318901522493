@import '../../styles/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 64px;
    gap: 64px;

    @media(max-width: $brPoint3) {
        padding: 32px;
        gap: 40px;
    }

    @media(max-width: $brPoint4) {
        padding: 32px 16px;
    }
}

.head {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 100%;
    max-width: 1312px;

    h1 {
        /* Semibold/40 */

        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 1.4;
        /* identical to box height, or 140% */

        text-align: center;
        letter-spacing: 0.2px;

        /* Content/ContrastPrimary */

        color: #FFFFFF;
        margin: 0;



        @media(max-width: $brPoint4) {
            font-size: 32px;
        }

        @media(max-width: $brPoint5) {
            font-size: 28px;
        }

    }

    p {
        /* Medium / 18 */

        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.56;
        /* identical to box height, or 156% */

        text-align: center;

        /* Content/ContrastSecondary */

        color: rgba(255, 255, 255, 0.56);
        margin: 0;

        @media(max-width: $brPoint4) {
            font-size: 16px;
        }

        @media(max-width: $brPoint5) {
            font-size: 14px;
        }

    }
}

.steps {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;
    gap: 32px;

    max-width: 1312px;
    width: 100%;
    position: relative;

    .arrow {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -100%);
    }

    @media(max-width: $brPoint4) {
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    .step {
        /* Auto layout */

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        position: relative;

        .box {
            /* Auto layout */

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 16px;
            gap: 16px;
            padding-bottom: 64px;
            width: 100%;

            /* global/whiteAlpha/whiteAlpha3 */

            background: rgba(255, 255, 255, 0.05);
            /* global/whiteAlpha/whiteAlpha6 */

            border: 1px solid rgba(255, 255, 255, 0.12);
            border-radius: 8px;

            span {
                /* Auto layout */

                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 8px 15px;
                gap: 10px;

                width: 40px;
                height: 40px;

                /* global/purple/purple11 */

                background: #5A08BF;
                border-radius: 40px;

                /* Medium / 16 */

                font-family: $fontFamilyInter;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 1.5;
                /* identical to box height, or 150% */


                /* Content/ContrastPrimary */

                color: #FFFFFF;

            }

            p {
                /* Semibold/18 */

                font-family: $fontFamilyInter;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 1.56;
                /* identical to box height, or 156% */

                letter-spacing: 0.2px;

                /* Content/ContrastPrimary */

                color: #FFFFFF;
                margin: 0;

                @media(max-width: $brPoint4) {
                    font-size: 16px;
                }

                @media(max-width: $brPoint5) {
                    font-size: 14px;
                }
            }
        }

        .img {
            max-width: 80%;
            margin-top: -64px;
            position: relative;

            img {
                max-width: 100%;

                &.annotation1,
                &.annotation2 {
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                &.annotation1 {
                    transform: translateX(36%);
                }

                @media(max-width: $brPoint4) {
                    &.annotation1 {
                        display: none;
                    }
                }
            }
        }

        .info {
            /* Medium / 14 */

            font-family: $fontFamilyInter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.43;

            text-align: center;

            /* Content/Disabled */

            color: #B5BEC5;
            margin: 0;

        }
    }
}

.btn {
    border-radius: $borderRadius;
    background: $actionPrimaryNormal;
    border-color: $actionPrimaryNormal;
    height: 40px;
    padding: 0 16px;
    line-height: 40px;
    min-width: 150px;

    &:hover,
    &:active {
        background: $actionPrimaryHover;
        border-color: $actionPrimaryHover;
    }
}