import { Layout } from "antd";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { PRIVACY_POLICY } from "../../route-paths";
import * as styles from "./page-footer.module.scss";

interface PageFooterProps {}

const PageFooter: FunctionComponent<PageFooterProps> = () => {
  return (
    <Layout.Footer className={styles.footer}>
      <div className={styles.container}>
        <p>© 2022 Sherlock, All rights reserved.</p>
        <div className={styles.links}>
          <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
          {/* <Link to={"/"}>
            <LinkedInIcon />
          </Link>
          <Link to={"/"}>
            <FacebookIcon />
          </Link>
          <Link to={"/"}>
            <GithubIcon />
          </Link>
          <Link to={"/"}>
            <FingerIcon />
          </Link>
          <Link to={"/"}>
            <DribbleIcon />
          </Link> */}
        </div>
      </div>
    </Layout.Footer>
  );
};
export default PageFooter;
