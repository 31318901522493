import { Typography } from "antd";
import { FunctionComponent, useContext, useMemo } from "react";
import AppContext from "../../context/app-context";
import { DisplayType } from "../../types/display-type";
import * as styles from "./auth-form-layout.module.scss";
interface AuthFormLayoutProps {
  title?: string;
  secondaryTitle?: string;
  extraContent?: React.ReactNode;
}

const AuthFormLayout: FunctionComponent<AuthFormLayoutProps> = ({
  title,
  secondaryTitle,
  extraContent,
  children,
}) => {
  const { displayType } = useContext(AppContext);
  const sm = useMemo(
    () =>
      displayType === DisplayType.TABLET || displayType === DisplayType.PHONE,
    [displayType]
  );
  return (
    <div className={`${styles["form-layout"]} ${sm ? styles.sm : ""}`}>
      <div className={`${styles.header}`}>
        {title && <Typography.Title level={3}>{title}</Typography.Title>}
        {secondaryTitle && <Typography.Text>{secondaryTitle}</Typography.Text>}
      </div>
      <div className="form-layout-content">{children}</div>
      {extraContent && <div className={styles.extra}>{extraContent}</div>}
    </div>
  );
};

export default AuthFormLayout;
