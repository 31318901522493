@import '../../styles/variables';
@import '../..//styles/editor-screen.scss';

.canvas {
  background: $purple1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.text-area {
  position: absolute;
  top: 50px;
  left: 50px;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  background: $bgSurface;
  z-index: 1;
  border: $borderNeutral solid 1px;
  border-radius: $borderRadius;
  overflow: auto;

  &:global(.overflow) {

    .text,
    .shadow-text {
      top: 20px;
      transform: translate(-50%, 0);
    }
  }
}

.text,
.shadow-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  font-family: $fontFamilyInter;
  font-weight: 700;
  z-index: 10;
  color: #000;
  line-height: 1.2;

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    font-family: $fontFamilyInter;
    font-weight: 700;
    display: inline-block;
    mix-blend-mode: color;
    color: #c4c4c4;
    transform: scale(2);
  }

  &:before {
    content: '“';
  }

  &::after {
    content: '”';
  }
}

.shadow-text {
  z-index: 9;
  opacity: 0;
}