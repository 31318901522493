

export function getRgbValue(hexColor: string) {
  // remove # if present
  hexColor = hexColor.replace('#', '');
  // fix hex color
  if (hexColor.length === 4) {
    hexColor = hexColor.replace(/(\w)/g, '$1$1');
  }
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);
  return [r, g, b];
}
