import { AuthToken, User } from "@prisma/client";
import { Alert, Checkbox, Col, Form, Input, Row, Typography } from "antd";
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ResponseWithSuccess } from "../../../common/interfaces/ResponseWithSuccess";
import { buildErrorMessageFromError } from "../../../common/lib/buildErrorMessageFromError";
import { LocalStorageManager } from "../../../common/lib/local-storage-manager";
import ActionBtn from "../../components/action-btn/action-btn";
import AuthFormLayout from "../../components/auth-form-layout/auth-form-layout";
import GoogleSignInBtn from "../../components/google-sign-in-btn/google-sign-in-btn";
import AppContext from "../../context/app-context";
import PublicPageLayout from "../../layouts/public-page-layout";
import httpClient from "../../libs/http-client";
import { DASHBOARD, FORGOT_PASSWORD, SIGN_UP } from "../../route-paths";
import * as styles from "./index.module.scss";

type AccessToken = {
  access_token: string;
  refresh_token: string;
};
export interface SignInProps {}

const SignIn: FunctionComponent<SignInProps> = (props: SignInProps) => {
  const [form] = Form.useForm();
  const [busy, setBusy] = useState(false);
  const [serverMessage, setServerMessage] = useState<string>();
  const [serverMessageType, setServerMessageType] = useState<
    "success" | "error"
  >();
  const { setUser } = useContext(AppContext);
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useSearchParams();
  const { provider } = useParams();

  const onFinish = useCallback(
    async (values: any) => {
      setBusy(true);
      setServerMessage(undefined);
      try {
        const {
          data: user,
          meta: token,
          message,
        } = await httpClient.post<ResponseWithSuccess<User, AccessToken>>(
          "auth/sign-in",
          values
        );
        if (!user) {
          throw new Error(message);
        }
        if (token?.access_token) {
          LocalStorageManager.saveTokens(
            token.access_token,
            token.refresh_token
          );
        }
        setUser(user);
        setServerMessage(message ?? "Successfully signed in!");
        setServerMessageType("success");
        let to = decodeURIComponent(queryParams.get("redirectTo") || DASHBOARD);

        navigate(to);
      } catch (error) {
        setServerMessage(buildErrorMessageFromError(error));

        setServerMessageType("error");
      }
      setBusy(false);
    },
    [queryParams]
  );

  useEffect(() => {
    const code = queryParams.get("code");
    const state = queryParams.get("state");

    if (provider === "google" && code) {
      (async () => {
        setBusy(true);
        setServerMessage(undefined);
        try {
          const {
            data: user,
            meta: token,
            message,
          } = await httpClient.post<ResponseWithSuccess<User, AuthToken>>(
            `auth/social-login/google/${encodeURIComponent(code)}`
          );

          if (token?.token) {
            LocalStorageManager.saveTokens(token.token, token.refresh_token!);
          }
          setUser(user);
          setServerMessage(message ?? "Successfully signed in!");
          setServerMessageType("success");
          let to = DASHBOARD;
          if (state) {
            to = JSON.parse(state)?.redirectTo || DASHBOARD;
          }
          navigate(to);
        } catch (error) {
          setServerMessage(buildErrorMessageFromError(error));

          setServerMessageType("error");
        }
        setBusy(false);
      })();
    }
  }, [provider, queryParams, setUser]);

  return (
    <PublicPageLayout hideFooter title="Log in to your account">
      <div className={styles["form-area"]}>
        <AuthFormLayout
          title="Log in to your account"
          secondaryTitle="Welcome back! Please enter your details."
          extraContent={
            <Typography.Text>
              Don’t have an account?{" "}
              <Link to={SIGN_UP} className={styles.signup_link}>
                Sign Up
              </Link>
            </Typography.Text>
          }
        >
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
          >
            {serverMessage && (
              <Form.Item>
                <Alert
                  message={serverMessage}
                  type={serverMessageType}
                  showIcon
                />
              </Form.Item>
            )}
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  max: 255,
                  message: "Please enter your email",
                },
              ]}
              requiredMark={false}
            >
              <Input placeholder="Your email address" />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  min: 6,
                  max: 255,
                  message: "Please enter your password",
                },
              ]}
              requiredMark={false}
            >
              <Input.Password placeholder="Your password" />
            </Form.Item>
            <Form.Item>
              <Row gutter={8}>
                <Col xs={24} md={12}>
                  <Form.Item noStyle name="remember">
                    <Checkbox>Remember for 30 days</Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} className={styles["forgot-password-col"]}>
                  <Link to={FORGOT_PASSWORD}>Forgot Password</Link>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <ActionBtn
                className={styles["login_button"]}
                type="primary"
                htmlType="submit"
                block
                busy={busy}
              >
                Log in
              </ActionBtn>
            </Form.Item>
            <Form.Item>
              <GoogleSignInBtn />
            </Form.Item>
          </Form>
        </AuthFormLayout>
      </div>
    </PublicPageLayout>
  );
};
export default SignIn;
