import { User } from "@prisma/client";
import { Alert, Form, Input, Typography } from "antd";
import { FunctionComponent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ResponseWithSuccess } from "../../../common/interfaces/ResponseWithSuccess";
import { buildErrorMessageFromError } from "../../../common/lib/buildErrorMessageFromError";
import ActionBtn from "../../components/action-btn/action-btn";
import AuthFormLayout from "../../components/auth-form-layout/auth-form-layout";
import GoogleSignInBtn from "../../components/google-sign-in-btn/google-sign-in-btn";
import PublicPageLayout from "../../layouts/public-page-layout";
import httpClient from "../../libs/http-client";
import { ACTIVATE_ACCOUNT, SIGN_IN } from "../../route-paths";
import * as styles from "./index.module.scss";

/* eslint-disable-next-line */
export interface SignUpProps {}

const SignUp: FunctionComponent<SignUpProps> = (props: SignUpProps) => {
  const [form] = Form.useForm();
  const [busy, setBusy] = useState(false);
  const [serverMessage, setServerMessage] = useState<string>();
  const [serverMessageType, setServerMessageType] = useState<
    "success" | "error"
  >();
  const onFinish = async (values: any) => {
    setBusy(true);
    setServerMessage(undefined);
    try {
      const { message } = await httpClient.post<ResponseWithSuccess<User>>(
        "auth/sign-up",
        values
      );
      setServerMessage(message ?? "Successfully signed up!");
      setServerMessageType("success");
    } catch (error) {
      setServerMessage(buildErrorMessageFromError(error));

      setServerMessageType("error");
    }
    setBusy(false);
  };
  const navigate = useNavigate();
  return (
    <PublicPageLayout hideFooter title="Sign Up" className="forms-content">
      <div className={styles["form-area"]}>
        <AuthFormLayout
          title="Create new account"
          secondaryTitle="Welcome back! Please enter your details."
          extraContent={
            <Typography.Text>
              Already have an account? <Link to={SIGN_IN} className={styles.login_link}>Sign In</Link>
            </Typography.Text>
          }
        >
          <Form form={form} requiredMark={false} layout="vertical" onFinish={onFinish}>
            {serverMessage && (
              <Form.Item
                help={
                  serverMessageType === "success" ? (
                    <Typography.Text className={styles["help-text"]}>
                      <Link to={ACTIVATE_ACCOUNT}>Activate Now</Link>
                    </Typography.Text>
                  ) : null
                }
              >
                <Alert
                  message={serverMessage}
                  type={serverMessageType}
                  showIcon
                />
              </Form.Item>
            )}

            <Form.Item
              name="name"
              label="Your name"
              rules={[
                {
                  required: true,
                  min: 2,
                  max: 255,
                  message: "Please enter your name",
                },
              ]}
            >
              <Input placeholder="Your name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  max: 255,
                  message: "Please enter your email",
                },
              ]}
            >
              <Input placeholder="Your email address" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  min: 6,
                  max: 255,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input.Password placeholder="Your Password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  min: 6,
                  max: 255,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Password does not match"));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Repeat Your Password" />
            </Form.Item>
            {/* <Form.Item className="agreement">
              <Form.Item
                name="agreement"
                valuePropName="checked"
                noStyle
                rules={[
                  {
                    required: true,
                    message:
                      "You can't proceed without accepting the agreement",
                  },
                ]}
              >
                <Checkbox>
                  I have read and accepted the{" "}
                  <Link to={TERMS_CONDITION}>Terms and Conditions</Link>
                </Checkbox>
              </Form.Item>
            </Form.Item> */}
            <Form.Item>
              <ActionBtn className={styles.signup_button} type="primary" htmlType="submit" block busy={busy}>
                Create new account
              </ActionBtn>
            </Form.Item>
            <Form.Item>
              <GoogleSignInBtn />
            </Form.Item>
          </Form>
        </AuthFormLayout>
      </div>
    </PublicPageLayout>
  );
};
export default SignUp;
