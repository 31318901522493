@import '../../styles/form-styles.scss';

.forgot-password-col {
  margin-top: 12px;
  text-align: left;
  @media (min-width: 768px) {
    margin-top: 0;
    text-align: right;
  }
}

.login_button {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.signup_link {
  font-weight: 500;
}
