@import '../../styles/variables';
@import '../../styles/mixins';



.section-title {
    @extend .font-iowan-old-style;
    font-size: 48px;
    line-height: 1.17;
    letter-spacing: 4px;
    color: $contentContrastPrimary;
    margin: 0;

    @media(max-width: $brPoint4) {
        font-size: 32px;
    }

    @media(max-width: $brPoint4) {
        font-size: 28px;
    }
}

.top {
    @extend .flex-column-center;

    padding: 88px 64px;
    gap: 80px;

    @media(max-width: $brPoint3) {
        padding: 44px 32px;
        gap: 40px;
    }

    @media(max-width: $brPoint4) {
        padding: 44px 16px;
    }



    .topText {
        @extend .flex-column-center;
        gap: 40px;

        @media(max-width: $brPoint3) {
            gap: 20px;
        }

        .frame {
            @extend .flex-column-center;
            gap: 24px;

            @media(max-width: $brPoint4) {
                align-items: stretch;
            }
        }

        .texts {
            @extend .flex-column-center;
            gap: 16px;
            max-width: 706px;

            @media(max-width: $brPoint3) {
                gap: 8px;
            }

            h1 {

                text-align: center;

                @extend .section-title;
            }

            p {
                @extend .font-inter;
                font-size: $fontSizeMedium16;

                @media(max-width: $brPoint4) {
                    font-size: $fontSizeMedium14;
                }

                line-height: 1.5;
                text-align: center;
                letter-spacing: 0.2px;

                color: $contentContrastPrimary;
                margin: 0;
            }
        }

        a {
            @extend .flex-row-center;
            padding: 10px 16px;
            gap: 8px;

            background: $actionPrimaryNormal;
            border-radius: $borderRadius;

            @extend .font-inter;
            font-size: $fontSizeMedium14;
            line-height: 1.43;
            text-align: center;
            letter-spacing: 0.2px;
            color: $contentContrastPrimary;

            @media(max-width: $brPoint4) {
                display: block;
            }
        }
    }

    .screen {
        width: 100%;
        max-width: 820px;
        position: relative;
        display: flex;
        flex-direction: column;

        @media(max-width: $brPoint3) {}

        @media(max-width: $brPoint4) {}

        .switches {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-end;
            gap: 16px;

            @media(max-width: $brPoint4) {
                flex-direction: column;
                align-items: stretch;
                height: auto;
                margin-top: 10px;
                margin-bottom: 10px;
                gap: 0;
            }
        }

        .switch {
            cursor: pointer;
            padding: 16px;
            gap: 16px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            @media(max-width: $brPoint4) {
                align-items: center;
            }

            @extend .font-inter;

            font-size: $fontSizeMedium16;
            line-height: 1.5;

            text-align: center;
            letter-spacing: 0.2px;


            color: $contentContrastPrimary;
            background: transparent;
            border: none;
            border-radius: 4px 4px 0px 0px;

            &:hover,
            &:focus,
            &.active {
                background: $purple12;
                box-shadow: inset 0px 1px 0px #760FF5;
            }
        }

        .content {

            border-radius: 10px;
            overflow: hidden;

            img {
                max-width: 100%;
                max-height: 100%;
            }


        }

        .arrow1,
        .arrow2,
        .arrow4,
        .arrow5 {
            position: absolute;
        }

        .arrow1 {
            top: 89px;
            right: -240px;
        }

        .arrow2 {
            top: 217px;
            left: -211px;
        }

        .arrow4 {
            top: -3px;
            left: -120px;
        }

        .arrow5 {
            top: 133px;
            left: -217px;
        }

        @media(max-width: $brPoint1) {

            .arrow1,
            .arrow2,
            .arrow4,
            .arrow5 {
                transform: scale(.7);
            }

            .arrow1 {
                right: -200px;
            }

            .arrow2 {
                top: 206px;
                left: -178px;
            }

            .arrow4 {

                top: 50px;
                left: -124px;
                transform: scale(.7) rotate(-23deg);

            }

            .arrow5 {
                top: 135px;
                left: -188px;
            }
        }

        @media(max-width: 1150px) {

            .arrow1,
            .arrow2,
            .arrow4,
            .arrow5 {
                display: none !important;
            }
        }
    }


}

.border {
    height: 1px;
    background: $darkBlue8;
}


.frame2 {



    .content {
        @extend .flex-column-center;
        padding: 80px 64px;
        gap: 50px;

        @media(max-width: $brPoint3) {
            padding: 40px 32px;
            gap: 25px;
        }

        @media(max-width: $brPoint4) {
            padding: 40px 16px;
        }

        /* global/darkblue/darkblue10 */

        background: $darkBlue10;

        h1 {
            @extend .section-title;
            line-height: 1.1;
            text-align: center;
        }

        img {
            max-width: 600px;
            width: 90%;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .benefits {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            padding: 0px;
            gap: 32px;

            @media(max-width: $brPoint3) {
                gap: 16px;
            }

            @media(max-width: $brPoint4) {
                flex-direction: column;
                align-items: stretch;
            }

            .benefit {
                box-sizing: border-box;

                /* Auto layout */

                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 16px;
                gap: 16px;

                max-width: 342px;
                background: $whiteAlpha2;
                border: 1px solid $whiteAlpha3;
                border-radius: 8px;

                @media(max-width: $brPoint4) {
                    max-width: 100%;
                }

                .icon {
                    /* Auto layout */

                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 8px;
                    gap: 10px;

                    width: 40px;
                    height: 40px;

                    /* global/purple/purple11 */

                    background: $purple11;
                    border-radius: 40px;

                    path {
                        fill: #FFFFFF;
                        stroke: #FFFFFF;
                    }

                }

                h2 {
                    @extend .font-inter;
                    font-size: $fontSizeMedium18;


                    @media(max-width: $brPoint4) {
                        font-size: $fontSizeMedium16;
                    }

                    line-height: 1.56;
                    letter-spacing: 0.2px;
                    color: $contentContrastPrimary;
                    margin: 0;
                }

                p {
                    @extend .font-inter;
                    font-size: $fontSizeMedium16;


                    @media(max-width: $brPoint4) {
                        font-size: $fontSizeMedium14;
                    }

                    line-height: 1.5;
                    color: rgba($contentContrastSecondary, .56);
                    margin: 0;
                }
            }

        }
    }
}

.frame3 {
    @extend .flex-column-center;
    padding: 123px 64px 12px;
    gap: 56px;

    @media(max-width: $brPoint3) {
        padding: 60px 32px 12px;
        gap: 28px;
    }

    @media(max-width: $brPoint4) {
        padding: 40px 16px 12px;
        gap: 16px;
    }

    .row {
        width: 100%;
        max-width: 1090px;

        @media(max-width: $brPoint2) {
            max-width: 900px;
        }



        @extend .flex-column-center;
        align-items: flex-start;

        @media(max-width: $brPoint4) {
            align-items: stretch;
        }



        .nav {
            box-sizing: border-box;

            /* Auto layout */

            display: inline-flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px;
            gap: 16px;


            @media(max-width: $brPoint4) {
                display: flex;
            }

            /* global/whiteAlpha/whiteAlpha3 */

            background: $whiteAlpha3;
            /* global/whiteAlpha/whiteAlpha3 */

            border: 1px solid $whiteAlpha3;
            border-radius: 8px;

            a {
                display: flex;
                border: none;
                flex-direction: row;
                align-items: flex-start;
                padding: 16px;
                gap: 12px;
                color: $contentContrastPrimary;

                border-radius: 4px;
                background: transparent;
                font-size: $fontSizeMedium16;
                line-height: 1.5;
                @extend .font-inter;

                &:hover,
                &:focus,
                &.active {
                    background: $purple11;
                }


                @media(max-width: $brPoint4) {
                    flex: 1;
                    justify-content: center;
                    padding: 8px;
                    font-size: $fontSizeMedium14;
                }
            }
        }
    }

    .previewRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1090px;
        margin-bottom: 37px;

        @media(max-width: $brPoint2) {
            max-width: 900px;
        }

        @media(max-width: $brPoint3) {
            gap: 16px;
        }

        @media(max-width: $brPoint4) {
            flex-direction: column;
            align-items: stretch;
            gap: 32px;
            margin-bottom: 0;
        }


        a {
            padding: 10px 16px;
            background: $actionPrimaryNormal;
            border-radius: $borderRadius;
            @extend .font-inter;
            font-size: $fontSizeMedium14;
            line-height: 1.43;
            text-align: center;
            letter-spacing: 0.2px;
            color: $contentContrastPrimary;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 16px;
            position: relative;
            max-width: 416px;

            @media(max-width: $brPoint3) {
                flex: 1;
            }

            @media(max-width: $brPoint4) {
                max-width: 100%;
                order: 0;
                align-items: stretch;

                a {
                    display: none;
                }
            }

            h1 {
                @extend .section-title;
                line-height: 1.4;
                margin: 0;
            }

            p {
                @extend .font-inter;
                font-size: $fontSizeMedium18;
                line-height: 1.5;
                color: $contentContrastPrimary;
                margin: 0;


                @media(max-width: $brPoint4) {
                    font-size: $fontSizeMedium16;
                    color: $contentSecondary;
                }

                @media(max-width: $brPoint5) {
                    font-size: $fontSizeMedium14;
                }
            }



            .arrow {
                position: absolute;
                bottom: 17px;
                right: -84px;

                @media(max-width: $brPoint3) {
                    display: none;
                }
            }

            .flippedArrow {
                right: auto;
                transform: scale(-.5, .5) translate(200%, 50%);
                bottom: 50%;
                left: 0;
            }

        }

        .preview {
            img {
                max-width: 100%;
            }

            a {
                display: none;
            }

            @media(max-width: $brPoint2) {
                max-width: 400px;
            }

            @media(max-width: $brPoint3) {
                flex: 1;
            }

            @media(max-width: $brPoint4) {
                max-width: 100%;
                order: 1;
                display: flex;
                flex-direction: column;
                align-items: center;

                a {
                    display: block;
                    width: 100%;
                }
            }


        }

    }
}

.frame4 {
    .content {
        @extend .flex-column-center;
        padding: 80px 64px;
        background: $darkBlue10;

        @media(max-width: $brPoint3) {
            padding: 40px 32px;
        }

        @media(max-width: $brPoint4) {
            padding: 40px 16px;
        }

        p {
            max-width: 1090px;
            margin-bottom: 0;

            @media(max-width: $brPoint2) {
                max-width: 900px;
            }

            font-family: $fontFamilyIowanOldStyle;
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 1.25;

            @media (max-width: $brPoint2) {
                font-size: 42px;
            }

            @media (max-width: $brPoint3) {
                font-size: 36px;
            }

            @media (max-width: $brPoint4) {
                font-size: 28px;
            }

            @media (max-width: $brPoint5) {
                font-size: 24px;
            }

            letter-spacing: 0.2px;
            color: $contentContrastPrimary;


        }
    }
}

.frame5 {
    @extend .flex-column-center;
    padding: 98px 176px;
    gap: 56px;

    @media(max-width: $brPoint2) {
        padding: 80px 64px;
    }

    @media(max-width: $brPoint3) {
        padding: 40px 32px;
    }

    @media(max-width: $brPoint4) {
        padding: 40px 16px;
    }

    .pricingTitle {
        width: 100%;
        max-width: 1090px;

        @media(max-width: $brPoint2) {
            max-width: 900px;
        }

        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .pricingTitle h2 {
        @extend .font-inter;
        font-weight: 600;
        font-size: 40px;
        line-height: 1.4;

        letter-spacing: 0.2px;
        color: $contentContrastPrimary;
        margin: 0;

        @media(max-width: $brPoint4) {
            font-size: $fontSizeMedium24;
        }
    }

    .pricingTitle span {
        padding: 6px 16px;
        display: inline-block;
        @extend .font-inter;

        background: $purple2;
        border-radius: $borderRadius;

        font-size: 18px;
        line-height: 1.55;



        text-align: center;
        letter-spacing: 0.2px;

        color: $actionPrimaryNormal;

        @media(max-width: $brPoint4) {
            font-size: $fontSizeMedium14;
            padding: 3px 8px;
        }
    }

    .packages {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 32px;
        width: 100%;
        max-width: 1090px;

        @media(max-width: $brPoint2) {
            max-width: 900px;
            gap: 16px;
        }

        @media(max-width: $brPoint4) {
            flex-direction: column;
            align-items: stretch;
        }
    }

    .package {
        /* Auto layout */

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        gap: 11px;

        max-width: 340.67px;

        @media(max-width: $brPoint4) {
            max-width: 100%;
        }

        /* Background/Surface */

        background: $bgSurface;
        /* Border/Neutral */

        border: 1px solid $borderNeutral;
        /* Elevations/Depth01 */

        box-shadow: 0px 0px 1px rgba(17, 24, 28, 0.16),
        0px 1px 4px rgba(17, 24, 28, 0.16);
        border-radius: 8px;

        >span {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px;
            gap: 10px;

            width: 40px;
            height: 40px;

            /* global/purple/purple2 */

            background: #FAF5FE;
            border-radius: 40px;

            path {
                fill: $actionPrimaryNormal;
                stroke: $actionPrimaryNormal;
            }
        }

        >h3 {
            /* Semibold/18 */

            font-family: $fontFamilyInter;
            font-style: normal;
            font-weight: 600;
            font-size: $fontSizeMedium18;
            line-height: 1.55;
            /* identical to box height, or 156% */

            letter-spacing: 0.2px;

            /* Content/Primary */

            color: $contentPrimary;
            margin: 0;

            @media(max-width: $brPoint4) {
                font-size: $fontSizeMedium16;
            }
        }

        p {
            /* Medium / 14 */

            @extend .font-inter;
            font-weight: 500;
            font-size: $fontSizeMedium16;
            line-height: 1.5;

            &.small {
                font-size: $fontSizeMedium14;
                line-height: 1.4;
            }

            @media(max-width: $brPoint4) {
                font-size: $fontSizeMedium14;

                &.small {
                    font-size: $fontSizeMedium12;
                }
            }

            color: $contentSecondary;
            margin: 0;
        }

        >.packageInfo {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            padding: 0 0 4px;
            display: flex;
            width: 100%;


            box-shadow: inset 0px -1px 0px #DFE3E6;

            h4 {
                /* Semibold/16 */

                font-family: $fontFamilyInter;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                letter-spacing: 0.2px;

                /* Content/Primary */

                color: $contentPrimary;
            }
        }

        .feature {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;


            >span {
                width: 20px;
                height: 20px;

                /* Background/PositiveSubtle */

                background: $bgPositiveSubtle;
                border-radius: 14px;

                path {
                    fill: $contentPositive;
                    stroke: $contentPositive;
                }
            }

        }

        a {
            display: block;
            width: 100%;
            padding: 10px 16px;
            border: 0;
            background: $actionPrimaryNormal;
            border-radius: 6px;
            font-family: $fontFamilyInter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            text-align: center;
            letter-spacing: 0.2px;

            /* Content/ContrastPrimary */

            color: $contentContrastPrimary;
        }
    }

}

.frame6 {
    @extend .flex-column-center;
    padding: 88px 64px;
    margin-top: 17px;

    @media(max-width: $brPoint3) {
        padding: 64px 32px;
    }

    @media(max-width: $brPoint4) {
        padding: 48px 16px;
    }

    .top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 32px;
        width: 100%;
        max-width: 1312px;

        @media (max-width: $brPoint1) {
            max-width: 1090px;
        }

        @media(max-width: $brPoint2) {
            max-width: 900px;
        }

        .text {
            width: 100%;
            max-width: 746px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            display: flex;

            @media(max-width: $brPoint5) {
                max-width: 100%;
                align-items: stretch;
            }

            p {
                /* Semibold/16 */

                font-family: $fontFamilyInter;
                font-style: normal;
                font-weight: 600;
                font-size: $fontSizeMedium16;
                line-height: 1.5;
                /* identical to box height, or 150% */

                letter-spacing: 0.2px;

                /* Background/Surface */

                color: #FFFFFF;
                margin: 0;

                @media(max-width: $brPoint4) {
                    font-size: $fontSizeMedium14;
                }

            }

            h1 {
                font-family: $fontFamilyIowanOldStyle;
                font-style: normal;
                font-weight: 700;
                font-size: 44px;
                line-height: 1.2;

                letter-spacing: 0.2px;

                /* Background/Surface */

                color: #FFFFFF;
                margin: 0;

                @media(max-width: $brPoint3) {
                    font-size: 36px;
                }

                @media(max-width: $brPoint4) {
                    font-size: 32px;
                }

                @media(max-width: $brPoint4) {
                    font-size: 28px;
                }

            }
        }

        a {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 16px;
            margin-top: 19px;
            /* Action/Primary/Normal */

            background: #760FF5;
            border-radius: 6px;
            /* Semibold/14 */

            font-family: $fontFamilyInter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            text-align: center;
            letter-spacing: 0.2px;

            /* Content/ContrastPrimary */

            color: #FFFFFF;
        }
    }

    .bottom {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0px;
        gap: 32px;
        width: 100%;
        max-width: 1312px;

        @media (max-width: $brPoint1) {
            max-width: 1090px;
        }

        @media(max-width: $brPoint2) {
            max-width: 900px;
        }



        .col1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 16px;

            max-width: 317px;

            h2 {
                font-family: $fontFamilyPoppins;
                font-style: normal;
                font-weight: 600;
                font-size: $fontSizeMedium24;
                line-height: .83;
                /* identical to box height, or 83% */


                /* Content/ContrastPrimary */
                margin: 0;

                color: #FFFFFF;

                @media(max-width: $brPoint4) {
                    font-size: 20px;
                }

                @media(max-width: $brPoint5) {
                    font-size: 18px;
                }
            }

            p {
                /* Semibold/16 */

                font-family: $fontFamilyInter;
                font-style: normal;
                font-weight: 600;
                font-size: $fontSizeMedium16;
                line-height: 1.5;

                letter-spacing: 0.2px;

                /* Content/ContrastSecondary */
                margin: 0;

                color: rgba(255, 255, 255, 0.56);

                @media(max-width: $brPoint4) {
                    font-size: 14px;
                }
            }
        }

        .col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 16px;

            max-width: 167px;

            h4 {
                font-family: $fontFamilyInter;
                font-style: normal;
                font-weight: 600;
                font-size: $fontSizeMedium18;
                line-height: 1.56;
                /* identical to box height, or 156% */

                letter-spacing: 0.2px;

                /* Content/ContrastSecondary */
                margin: 0;

                color: rgba(255, 255, 255, 0.56);

                @media(max-width: $brPoint4) {
                    font-size: $fontSizeMedium16;
                }

            }

            .links {
                /* Auto layout */

                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 12px;

                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 8px;

                    /* Semibold/18 */

                    font-family: $fontFamilyInter;
                    font-style: normal;
                    font-weight: 600;
                    font-size: $fontSizeMedium18;
                    line-height: 1.56;
                    /* identical to box height, or 156% */

                    letter-spacing: 0.2px;

                    /* Content/ContrastPrimary */

                    color: #FFFFFF;

                    @media(max-width: $brPoint4) {
                        font-size: $fontSizeMedium16;
                    }

                }
            }
        }

        @media(max-width: $brPoint4) {
            flex-direction: column;
            align-items: stretch;
            gap: 16px;

            .col1,
            .col {
                max-width: 100%;
            }
        }
    }
}