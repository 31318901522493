import { FunctionComponent, lazy, Suspense } from "react";
import SherlockSpinner from "../../common/components/sherlock-spinner";

interface LazyComponentProps {
  component: Promise<any>;
}

const LazyComponent: FunctionComponent<LazyComponentProps> = ({
  component,
}) => {
  const Component = lazy(() => component);
  return (
    <Suspense
      fallback={<SherlockSpinner center size="large" label="Sherlock" />}
    >
      <Component />
    </Suspense>
  );
};

export default LazyComponent;
