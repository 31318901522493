import { HttpClientConfig } from "../interfaces/http-client-config";
import { TokenPair } from "../interfaces/token-pair";

type Json = {
  [key: string]: any;
};

class SherlockHttpClient {
  constructor(private config: HttpClientConfig) {}

  async renewToken(refresh_token: string) {
    return await new Promise<TokenPair | null | undefined>(
      (resolve, reject) => {
        // with fetch api
        const url = `${process.env.API_URL}/api/auth/refresh`; // because this url should be pure, so not relying on getUrl()
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ refresh_token }),
        })
          .then((r) => r.json())
          .then((r) => r.data)
          .then((data) => {
            if (data?.access_token) {
              resolve(data);
            } else {
              resolve(null);
            }
          })
          .catch((e) => {
            console.error(e);
            resolve(null);
          });
      }
    );
  }

  getUrl(url: string) {
    if (!url) return url;
    // if starts with blob: or http: or https:, or the apiUrl return it
    const apiUrl = this.config.baseUrl;
    if (
      url.startsWith("blob:") ||
      url.startsWith("http:") ||
      url.startsWith("https:") ||
      url.startsWith("data:") ||
      url.startsWith(apiUrl)
    ) {
      return url;
    }
    // if starts with slash, remove it
    if (url.startsWith("/")) {
      url = url.substr(1);
    }
    return `${apiUrl}/${url}`;
  }

  async getHeaders(headers?: { [key: string]: string }) {
    let headersToSend: { [key: string]: string } = {
      ...this.config.headers,
      ...headers,
      Accept: "application/json",
    };

    let tokens = await this.config.localStorageManager.getTokens();
    if (!tokens) {
      return headersToSend;
    }
    let { access_token, refresh_token } = tokens;
    try {
      if (!access_token && refresh_token) {
        const newToken = await this.renewToken(refresh_token);

        if (newToken) {
          await this.config.localStorageManager.saveTokens(
            newToken.access_token,
            newToken.refresh_token
          );
          access_token = newToken.access_token;
        }
      }
    } catch (e) {}
    headersToSend.Authorization = `Bearer ${access_token}`;

    return headersToSend;
  }

  async buildError(e: Response) {
    const data = await e.json();
    const status = e.status;
    const statusText = e.statusText;
    return {
      status,
      statusText,
      data,
    };
  }

  async makeRequest(
    url: string,
    method: string,
    data?: any,
    headers?: { [key: string]: string }
  ) {
    const headersToSend = await this.getHeaders(headers);
    const path = this.getUrl(url);
    let body: any = data;
    // if data is not formData, then convert it to string. Also, add the content-type header application/json
    if (data && !(data instanceof FormData)) {
      headersToSend["Content-Type"] = "application/json";
      body = JSON.stringify(data);
    }

    return await new Promise<any>(async (resolve, reject) => {
      fetch(path, {
        method,
        headers: headersToSend,
        body,
      })
        .then(async (r) => {
          // check the status
          if (r.status >= 200 && r.status < 300) {
            const json = await r.json();
            resolve(json);
          } else {
            this.buildError(r).then(reject);
          }
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  }

  async get<T = any>(url: string, headers?: { [key: string]: string }) {
    return (await this.makeRequest(url, "GET", undefined, headers)) as T;
  }

  async post<T = any>(
    url: string,
    data?: any,
    headers?: { [key: string]: string }
  ) {
    return (await this.makeRequest(url, "POST", data, headers)) as T;
  }

  async put<T = any>(
    url: string,
    data?: any,
    headers?: { [key: string]: string }
  ) {
    return (await this.makeRequest(url, "PUT", data, headers)) as T;
  }

  async delete<T = any>(url: string, headers?: { [key: string]: string }) {
    return (await this.makeRequest(url, "DELETE", undefined, headers)) as T;
  }

  async patch<T = any>(
    url: string,
    data?: any,
    headers?: { [key: string]: string }
  ) {
    return (await this.makeRequest(url, "PATCH", data, headers)) as T;
  }
}

export default SherlockHttpClient;
