import { FunctionComponent, lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { toUrl } from "../common/lib/toUrl";
import { ProtectionLevel } from "../common/types/protection-level";
import LazyComponent from "./common/lazy-component";
import { AppContextProvider } from "./context/app-context";
import GuardedRoute from "./guarded-route";
import ActivateAccount from "./pages/activate-account";
import Error from "./pages/error";
import ForgotPassword from "./pages/forgot-password";
import Homepage2 from "./pages/home";
import OnBoardingPage from "./pages/on-boarding";
import PrivacyPolicyPage from "./pages/privacy-policy";
import ResetPassword from "./pages/reset-password";
import SharePage from "./pages/share";
import SignIn from "./pages/sign-in";
import SignUp from "./pages/sign-up";
import { TermsAndConditions } from "./pages/terms-and-conditions";
import { WhatsNew } from "./pages/whats-new";
import {
  ACTIVATE_ACCOUNT,
  DASHBOARD,
  ERROR_PAGE,
  FORGOT_PASSWORD,
  HOMEPAGE,
  PRIVACY_POLICY,
  RESET_PASSWORD,
  SHARE,
  SIGN_IN,
  SIGN_IN_WITH_PROVIDER,
  SIGN_UP,
  TERMS_CONDITION,
  WELCOME,
  WHATS_NEW,
} from "./route-paths";

const Dashboard = lazy(() => import("./dashboard-routes"));

const Main: FunctionComponent = () => {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<Homepage2 />} path={HOMEPAGE} />
          <Route element={<SharePage />} path={SHARE} />
          <Route
            element={
              <GuardedRoute level={ProtectionLevel.PublicOnly}>
                <SignIn />
              </GuardedRoute>
            }
            path={SIGN_IN}
          />
          <Route
            element={
              <GuardedRoute level={ProtectionLevel.PublicOnly}>
                <SignIn />
              </GuardedRoute>
            }
            path={SIGN_IN_WITH_PROVIDER}
          />
          <Route
            element={
              <GuardedRoute level={ProtectionLevel.PublicOnly}>
                <SignUp />
              </GuardedRoute>
            }
            path={SIGN_UP}
          />
          <Route
            element={
              <GuardedRoute level={ProtectionLevel.PublicOnly}>
                <ForgotPassword />
              </GuardedRoute>
            }
            path={FORGOT_PASSWORD}
          />
          <Route
            element={
              <GuardedRoute level={ProtectionLevel.PublicOnly}>
                <ResetPassword />
              </GuardedRoute>
            }
            path={RESET_PASSWORD}
          />
          <Route element={<ActivateAccount />} path={ACTIVATE_ACCOUNT} />
          <Route element={<TermsAndConditions />} path={TERMS_CONDITION} />
          <Route element={<PrivacyPolicyPage />} path={PRIVACY_POLICY} />
          <Route element={<WhatsNew />} path={WHATS_NEW} />
          <Route
            element={
              <GuardedRoute level={ProtectionLevel.Private}>
                <LazyComponent component={import("./dashboard-routes")} />
              </GuardedRoute>
            }
            path={`${DASHBOARD}/*`}
          />
          <Route element={<Error />} path={ERROR_PAGE} />
          <Route element={<OnBoardingPage />} path={WELCOME} />
          <Route
            path="*"
            element={
              <Navigate
                to={toUrl(
                  ERROR_PAGE,
                  {},
                  {
                    title: "Page not found",
                    subTitle: "The page you are looking for can not be found!",
                    status: 404,
                  }
                )}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
};

export default Main;
