import { Space, Typography } from "antd";
import { FunctionComponent } from "react";
import { LighteningBoltIcon } from "../../icons";
import styles from "./index.module.scss";
interface SherlockSpinnerProps {
  label?: string;
  center?: boolean;
  style?: React.CSSProperties;
  size?: "small" | "default" | "large";
}

const SherlockSpinner: FunctionComponent<SherlockSpinnerProps> = ({
  label,
  center,
  style,
  size = "default",
}) => {
  return (
    <Space
      direction="vertical"
      align="center"
      className={`${styles.spinner} ${center ? styles.center : ""} ${
        styles[size]
      }`}
      style={style}
    >
      <LighteningBoltIcon />
      {label && <Typography.Text>{label}</Typography.Text>}
    </Space>
  );
};

export default SherlockSpinner;
