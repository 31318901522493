@import '../../styles/form-styles.scss';


.signup_button {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

.login_link {
  font-weight: 500;
}