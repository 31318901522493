import { Image } from "antd";
import { FallbackImage } from "../../../common/constants";
import usePreparedUrl from "../../../common/hooks/usePreparedUrl";
import { ViewerProp } from "../../common/viewer-prop";
import localStorageManager from "../../libs/local-storage-manager";
import * as styles from "./bookmark.module.scss";

export function Bookmark(props: ViewerProp) {
  const item = props.item;
  const [imgSrc] = usePreparedUrl(
    localStorageManager,
    props?.item?.bookmark_screenshot_url
  );
  return (
    <div className={styles.canvas}>
      <a
        target="_blank"
        href={props.item.source || "#"}
        className={styles["text-area"]}
      >
        {imgSrc && (
          <Image
            src={imgSrc}
            alt={props.item.title}
            className={styles.image}
            fallback={FallbackImage}
            preview={false}
          />
        )}
      </a>
    </div>
  );
}

export default Bookmark;
