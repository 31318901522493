import { ExtensionEventPayload } from "../types/extension-event-payload";
import { ExtensionEvents } from "../types/extension-events";

const scope = "sherlock-extension";
export default function prepareMessageForExtension(
  event: ExtensionEvents,
  payload?: any
): ExtensionEventPayload {
  return {
    event,
    payload,
    scope,
  };
}

export function validateMessageScope(message: any) {
  return message?.scope === scope;
}
