@import '../../styles/variables';
@import '../../styles/editor-screen.scss';

.canvas {
  background: $purple1;
  background: $bgSurface;
  border: $borderNeutral solid 1px;
  border-radius: $borderRadius;
  overflow: auto;

  a,
  :global(.ant-image) {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}