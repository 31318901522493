@import '../../styles/variables';

.btn {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
}

.inner {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: $borderRadius;
}

.picker {
  top: 100%;
  z-index: 100;
}
