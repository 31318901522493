import { Button, ButtonProps } from "antd";
import clsx from "clsx";
import { FunctionComponent, useMemo } from "react";
import SherlockSpinner from "../../../common/components/sherlock-spinner";
import styles from "./action-btn.module.scss";

interface ActionBtnProps extends ButtonProps {
  busy?: boolean;
}

const ActionBtn: FunctionComponent<ActionBtnProps> = ({
  children,
  busy,
  ...props
}) => {
  const disabled = busy || props.disabled;
  const className = useMemo(() => {
    const classNames = [styles.btn];
    if (props.className) {
      classNames.push(props.className);
    }
    return clsx(classNames);
  }, [props.className]);
  return (
    <Button {...props} disabled={disabled} className={className}>
      {children} {busy && <SherlockSpinner style={{ marginLeft: 10 }} />}
    </Button>
  );
};

export default ActionBtn;
