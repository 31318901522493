import { Button, Result } from "antd";
import { useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import PublicPageLayout from "../../layouts/public-page-layout";
import * as styles from "./index.module.scss";

/* eslint-disable-next-line */
export interface ErrorProps {}

export function Error(props: ErrorProps) {
  const [search, setSearch] = useSearchParams();

  const status = useMemo(() => search.get("status") || "error", [search]);
  const title = useMemo(
    () => search.get("title") || "Something went wrong!",
    [search]
  );
  const subTitle = useMemo(
    () => search.get("subTitle") || "Please try again later.",
    [search]
  );

  return (
    <PublicPageLayout>
      <Result
        className={styles.page}
        status={status || ("error" as any)}
        title={title}
        subTitle={subTitle}
        extra={
          <Button type="default">
            <Link to={"/"}>Go to home </Link>
          </Button>
        }
      />
    </PublicPageLayout>
  );
}

export default Error;
