import { TokenPair } from "../interfaces/token-pair";

export abstract class LocalStorageManagerBase {
  abstract accessTokenKey: string;
  abstract refreshTokenKey: string;
  abstract getTokens(): Promise<TokenPair | null | undefined>;
  abstract saveTokens(
    accessToken?: string,
    refreshToken?: string
  ): Promise<void>;
  abstract clearTokens(): Promise<void>;
}
