import { User } from "@prisma/client";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  createContext,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from "react";
import SherlockSpinner from "../../common/components/sherlock-spinner";
import { ResponseWithSuccess } from "../../common/interfaces/ResponseWithSuccess";
import dayjs from "../../common/lib/dayjs";
import httpClient from "../libs/http-client";
import bootIntercom, { IntercomConfig } from "../libs/intercom";
import localStorageManager from "../libs/local-storage-manager";
import { DisplayType } from "../types/display-type";
type AppContextType = {
  appState: "loading" | "loaded" | "error";
  user?: User;
  setUser: (user?: User) => void;
  displayType: DisplayType;
};

const AppContext = createContext<AppContextType>({} as AppContextType);

export const AppContextProvider: FunctionComponent<Partial<AppContextType>> = ({
  children,
  ...props
}) => {
  const [user, setUser] = useState<User | undefined>(props.user);
  const [appState, setAppState] = useState<"loading" | "loaded" | "error">(
    props.appState || "loading"
  );
  const breakpoint = useBreakpoint();
  const displayType: DisplayType = useMemo(() => {
    if (breakpoint.lg || breakpoint.xl || breakpoint.xxl)
      return DisplayType.DESKTOP;
    if (breakpoint.md) return DisplayType.TABLET;
    return DisplayType.PHONE;
  }, [breakpoint]);

  useEffect(() => {
    (async () => {
      try {
        const tokens = await localStorageManager.getTokens();
        if (!(tokens && tokens.access_token && tokens.refresh_token)) {
          setUser(undefined);
          setAppState("loaded");
          return;
        }
        const { data } = await httpClient.get<ResponseWithSuccess<User>>(
          "/auth/me"
        );
        setUser(data);
      } catch (e) {}
      setAppState("loaded");
    })();
  }, []);

  useEffect(() => {
    let intercomUser: Partial<IntercomConfig> = {};
    if (user) {
      intercomUser = {
        name: user.name,
        email: user.email,
        user_id: user.id,
        created_at: dayjs(user.created_at).unix(),
      };
    }
    bootIntercom(intercomUser);
  }, [user?.email, user?.name]);
  return (
    <AppContext.Provider value={{ user, setUser, appState, displayType }}>
      {appState === "loading" && (
        <div className="splash-screen">
          <SherlockSpinner label="Loading..." center />
        </div>
      )}
      {appState === "loaded" && children}
    </AppContext.Provider>
  );
};

export default AppContext;
