import React, { FunctionComponent, useState } from "react";
import { TwitterPicker } from "react-color";
import * as styles from "./shape-color-picker.module.scss";

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
}

const ColorPicker: FunctionComponent<ColorPickerProps> = ({
  color,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSelect = (color: string) => {
    onChange(color);
    setIsOpen(false);
  };

  return (
    <span className={styles.btn}>
      <span
        className={styles.inner}
        style={{ background: color }}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <TwitterPicker
          className={styles.picker}
          color={color}
          onChange={(value) => onSelect(value.hex)}
        />
      )}
    </span>
  );
};

export default ColorPicker;
