export function buildErrorMessageFromError(error: any): string {
  const messages: string[] = [];
  if (error.message) {
    messages.push(error.message);
  }
  if (error.errors) {
    error.errors.forEach((err: any) => {
      if (err.message) {
        messages.push(err.message);
      }
    });
  }
  if (Array.isArray(error?.data?.errors)) {
    error.data.errors.forEach((err: any) => {
      if (typeof err === "string") {
        messages.push(err);
      } else if (err.message) {
        messages.push(err.message);
      }
    });
  }
  if (typeof error?.data?.message === "string") {
    messages.push(error.data.message);
  }
  if (messages.length === 0) {
    messages.push("Something went wrong");
  }

  return messages.join(".");
}
