import { Button } from "antd";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import annotation1 from "../../assets/annotation-1.png";
import annotation2 from "../../assets/annotation-2.png";
import step1 from "../../assets/steps/step-1.png";
import step2 from "../../assets/steps/step-2.png";
import arrow from "../../assets/welcome-arrow.png";
import PublicPageLayout from "../../layouts/public-page-layout";
import { DASHBOARD } from "../../route-paths";
import styles from "./index.module.scss";
interface OnBoardingPageProps { }

const OnBoardingPage: FunctionComponent<OnBoardingPageProps> = () => {
  return (
    <PublicPageLayout>
      <div className={styles.wrapper}>
        <div className={styles.head}>
          <h1>Welcome to the Sherlock</h1>
          <p>2 simple steps to set up</p>
        </div>
        <div className={styles.steps}>
          <img src={arrow} className={styles.arrow} />
          <div className={styles.step}>
            <div className={styles.box}>
              <span>1</span>
              <p>Pin Sherlock to your chrome bar</p>
            </div>
            <div className={styles.img}>
              <img src={step1} />
              <img src={annotation1} className={styles.annotation1} />
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.box}>
              <span>2</span>
              <p>Set up chrome extension in just 1-click</p>
            </div>
            <div className={styles.img}>
              <img src={step2} />
              <img src={annotation2} className={styles.annotation2} />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button type="primary" className={styles.btn}>
            <Link to={DASHBOARD}>Done</Link>
          </Button>
        </div>
      </div>
    </PublicPageLayout>
  );
};

export default OnBoardingPage;
