@import '../../styles/variables';

.logo {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #ffffff !important;
  font-style: normal;
  font-size: 20px;
  line-height: 64px;
  display: inline-flex;
  align-items: center;
  gap: 8px;

  small {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.8;
  }

  svg path {
    stroke: $white;

  }
}