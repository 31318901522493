import "./index.module.scss";

/* eslint-disable-next-line */
export interface TermsAndConditionsProps {}

export function TermsAndConditions(props: TermsAndConditionsProps) {
  return (
    <div>
      <h1>Welcome to TermsAndConditions!</h1>
    </div>
  );
}

export default TermsAndConditions;
