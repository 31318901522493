import { Layout } from "antd";
import { FunctionComponent } from "react";
import PageFooter from "../components/page-footer/page-footer";
import PageHeader from "../components/page-header/page-header";
import * as styles from "./public-page-layout.module.scss";
const { Header, Content, Footer } = Layout;
interface PublicPageLayoutProps {
  title?: string;
  hideFooter?: boolean;
  className?: string;
}

const PublicPageLayout: FunctionComponent<PublicPageLayoutProps> = ({
  title,
  children,
  hideFooter,
  className,
}) => {
  return (
    <Layout className={`public-page-layout ${styles.layout}`}>
      {!(window as any)?.insideExtension && <PageHeader />}

      <Content
        className={`${styles.content} ${className ?? ""} ${
          hideFooter ?? "has-footer"
        }`}
      >
        {children}
      </Content>

      {!hideFooter && !(window as any)?.insideExtension && <PageFooter />}
    </Layout>
  );
};

export default PublicPageLayout;
