import { gsap } from "gsap";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import {
  AcademicCapIcon,
  LighteningBoltIcon,
  PuzzleIcon
} from "../../../common/icons";
import graphic from "../../assets/graphic.svg";
import chromeStoreIcon from "../../assets/icon-chrome-store.png";
import chromeIcon from "../../assets/icon-chrome.png";
import previewArrow from "../../assets/preview/arrow.png";
import preview2 from "../../assets/preview/preview-bookmark.png";
import preview1 from "../../assets/preview/preview-screenshot.png";
import preview3 from "../../assets/preview/preview-text-snippet.png";
import arrow1 from "../../assets/screen1/arrow1.png";
import arrow2 from "../../assets/screen1/arrow2.png";
import arrow4 from "../../assets/screen1/arrow4.png";
import arrow5 from "../../assets/screen1/arrow5.png";
import webDashboard from "../../assets/screen1/screen-dashboard.png";
import extension from "../../assets/screen1/screen-extension.png";
import PublicPageLayout from "../../layouts/public-page-layout";
import styles from "./index.module.scss";

import ScrollTrigger from "gsap/ScrollTrigger";

// register scrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);
interface HomeProps { }

const cleanHash = (hash: string) => hash?.replace("#", "");

const Home: FunctionComponent<HomeProps> = () => {
  const [frame1ActiveTab, setFrame1ActiveTab] = useState<
    "dashboard" | "extension"
  >("dashboard");
  const f1ActiveAnimation = useRef<GSAPTimeline>();
  const f1Animation = useCallback((exitGroup, entryGroup) => {
    // stop previous animation
    f1ActiveAnimation.current?.pause();
    f1ActiveAnimation.current?.seek(0);

    f1ActiveAnimation.current = gsap.timeline();

    // hide other elements
    f1ActiveAnimation.current.set(exitGroup, { display: "none" });

    // add stagger animation
    const elements = document.querySelectorAll(entryGroup);
    // set initial opacity and display
    f1ActiveAnimation.current.set(elements, { opacity: 0, display: "block" });
    f1ActiveAnimation.current.fromTo(
      elements,
      { opacity: 0, display: "block" },
      { opacity: 1, duration: 0.5, stagger: 0.2, ease: "power2.out" }
    );
  }, []);

  useEffect(() => {
    if (frame1ActiveTab === "dashboard") {
      f1Animation(
        "[data-f1-el6]",
        "[data-f1-el1], [data-f1-el2], [data-f1-el3], [data-f1-el4], [data-f1-el5]"
      );
    } else {
      f1Animation(
        "[data-f1-el1], [data-f1-el2], [data-f1-el3], [data-f1-el4], [data-f1-el5]",
        "[data-f1-el6]"
      );
    }
  }, [frame1ActiveTab]);

  const [activeFeature, setActiveFeature] = useState<string>(
    cleanHash(window.location.hash)
  );

  useEffect(() => {
    // listen to hash change
    const hashChangeHandler = () => {
      setActiveFeature(cleanHash(window.location.hash));
    };
    window.addEventListener("hashchange", hashChangeHandler);
    let animations: GSAPTween[] = [];
    let timeout: any;
    const onPageLoad = () => {
      // groups
      const groups = document.querySelectorAll("[data-text-group]");

      groups.forEach((group) => {
        // get all immediate children
        const children = group.querySelectorAll("[data-text-group-el]");

        // const animation
        const animation = gsap.from(children, {
          y: 20,
          opacity: 0,
          duration: 0.5,
          stagger: 0.2,
          ease: "power2.out",
          scrollTrigger: {
            trigger: group,
            start: "top 50%",
          },
        });
        animations.push(animation);
      });
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // wait until page is loaded
    window.addEventListener("load", onPageLoad);

    // but if it takes too long, then just run it

    timeout = setTimeout(onPageLoad, 1000 * 10);

    return () => {
      window.removeEventListener("hashchange", hashChangeHandler);
      window.removeEventListener("load", onPageLoad);
      animations.forEach((animation) => animation.kill());
      clearTimeout(timeout);
    };
  }, []);

  return (
    <PublicPageLayout>
      <div className={styles.top} id="home">
        <div className={styles.topText}>
          <div className={styles.frame} data-text-group>
            <div className={styles.texts}>
              <h1 data-text-group-el>Tiny clues lead to big ideas</h1>
              <p data-text-group-el>
                A Chrome extension to capture, tag and organise all the sources
                that will inspire your next big idea: Screenshots, links,
                dashboards, videos, Text-Snippets etc.
              </p>
            </div>
            <a data-text-group-el href={process.env.EXTENSION_URL as string}>
              Install Chrome extension
            </a>
          </div>
        </div>
        <div className={styles.screen}>
          <div className={styles.switches}>
            <a
              className={`${styles.switch} ${frame1ActiveTab === "dashboard" ? styles.active : ""
                }`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setFrame1ActiveTab("dashboard");
              }}
            >
              <img src={chromeIcon} alt="chrome store icon" />
              Web Dashboard
            </a>

            <a
              className={`${styles.switch} ${frame1ActiveTab === "extension" ? styles.active : ""
                }`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setFrame1ActiveTab("extension");
              }}
            >
              <img src={chromeStoreIcon} alt="chrome store icon" />
              Browser Extension
            </a>
          </div>
          <div className={styles.content}>
            <img src={webDashboard} data-f1-el1 />
            <img src={extension} data-f1-el6 />
          </div>
          <img className={styles.arrow1} src={arrow1} data-f1-el2 />
          <img className={styles.arrow2} src={arrow2} data-f1-el3 />
          <img className={styles.arrow4} src={arrow4} data-f1-el4 />
          <img className={styles.arrow5} src={arrow5} data-f1-el5 />
        </div>
      </div>
      <div className={styles.frame2} id="benefits">
        <div className={styles.border}></div>
        <div className={styles.content} data-text-group>
          <h1 data-text-group-el>Map all your inspirations in one place</h1>
          <img src={graphic} />
          <h1 data-text-group-el>Work at the speed of creativity</h1>
          <div className={styles.benefits}>
            <div className={styles.benefit} data-text-group-el>
              <span className={styles.icon}>
                <PuzzleIcon />
              </span>
              <h2>Bring discipline to your life</h2>
              <p>
                Add structure to your thought process by mapping all your ideas
                and inspirations in one central place in the cloud.{" "}
              </p>
            </div>
            <div className={styles.benefit} data-text-group-el>
              <span className={styles.icon}>
                <LighteningBoltIcon />
              </span>
              <h2>Add more brain power</h2>
              <p>
                Use technology to your advantage and never lose great ideas.
                Track and organise them at the speed of light.
              </p>
            </div>
            <div className={styles.benefit} data-text-group-el>
              <span className={styles.icon}>
                <AcademicCapIcon />
              </span>
              <h2>Stay in your Flow State</h2>
              <p>
                Do not let tools interrupt your creative process. Reduce
                unnecessary distraction and extend the fluidity between your
                body and mind.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.border}></div>
      </div>
      <div className={styles.frame3} id="features">
        <div className={styles.row}>
          <div className={styles.nav}>
            <a
              href="#feature-screenshot"
              className={
                activeFeature === "feature-screenshot" ? styles.active : ""
              }
            >
              Screenshot
            </a>
            <a
              href="#feature-bookmark"
              className={
                activeFeature === "feature-bookmark" ? styles.active : ""
              }
            >
              Bookmark
            </a>
            <a
              href="#feature-text-snippet"
              className={
                activeFeature === "feature-text-snippet" ? styles.active : ""
              }
            >
              Text Snippet
            </a>
          </div>
        </div>
        <div
          className={styles.previewRow}
          id="feature-screenshot"
          data-text-group
        >
          <div className={styles.content}>
            <h1 data-text-group-el>Screenshot</h1>
            <p data-text-group-el>
              Tired of searching on your messy desktop the screenshots you
              captured last week? Organise, prioritise and find instantly the
              images that matter to you.
            </p>
            <a data-text-group-el href={process.env.EXTENSION_URL as string}>
              Install Chrome extension
            </a>
            <img
              data-text-group-el
              src={previewArrow}
              className={styles.arrow}
            />
          </div>
          <div className={styles.preview}>
            <img data-text-group-el src={preview1} />
            <a data-text-group-el href={process.env.EXTENSION_URL as string}>
              Install Chrome extension
            </a>
          </div>
        </div>
        <div
          className={styles.previewRow}
          id="feature-bookmark"
          data-text-group
        >
          <div className={styles.preview}>
            <img src={preview2} data-text-group-el />
            <a data-text-group-el href={process.env.EXTENSION_URL as string}>
              Install Chrome extension
            </a>
          </div>
          <div className={styles.content}>
            <h1 data-text-group-el>Bookmark</h1>
            <p data-text-group-el>
              Still sending Slack messages to yourself with blog posts, articles
              and video links to view later? Not ideal right? There is a better
              way!
            </p>
            <p data-text-group-el>
              Collect, tag and find in seconds all the URLs that inspire you.{" "}
            </p>
            <a data-text-group-el href={process.env.EXTENSION_URL as string}>
              Install Chrome extension
            </a>
            <img
              data-text-group-el
              src={previewArrow}
              className={`${styles.arrow} ${styles.flippedArrow}`}
            />
          </div>
        </div>
        <div
          className={styles.previewRow}
          id="feature-text-snippet"
          data-text-group
        >
          <div className={styles.content}>
            <h1 data-text-group-el>Text snippet</h1>
            <p data-text-group-el>
              Text snippets and quotes are unique pieces of content. They are
              too short to be bookmarked, usually used once or twice but… they
              are very valuable sources of inspiration. Save and organise text
              snippets from your favourite blogs or articles at the speed of
              light.
            </p>
            <a data-text-group-el href={process.env.EXTENSION_URL as string}>
              Install Chrome extension
            </a>
            <img
              data-text-group-el
              src={previewArrow}
              className={styles.arrow}
            />
          </div>
          <div className={styles.preview}>
            <img data-text-group-el src={preview3} />
            <a data-text-group-el href={process.env.EXTENSION_URL as string}>
              Install Chrome extension
            </a>
          </div>
        </div>
      </div>
      <div className={styles.frame4}>
        <div className={styles.border}></div>
        <div className={styles.content} data-text-group>
          <p data-text-group-el>
            Light-bulb moments do not exist, it is a myth! Big, bold and
            disruptive ideas are always a combination of deep researches,
            radical thinking mixed with various sources of inspiration. Sherlock
            was built for creative minds. For entrepreneurs, designers, writers,
            developers, researchers, who come up with hundreds of ideas each
            day, who tend to move fast and be scrappy by design. Sherlock will
            bring calm and structure to your life so you can simply focus on
            what you do best, being creative!
          </p>
        </div>
        <div className={styles.border}></div>
      </div>
      <div className={styles.frame6} id="pricing">
        <div className={styles.border}></div>

        <div className={styles.top}>
          <div className={styles.text}>
            <p>Get started with a free trial now.</p>
            <h1>Free for now!</h1>
            <a data-text-group-el href={process.env.EXTENSION_URL as string}>
              Install Chrome extension
            </a>
          </div>
        </div>
        <div className={styles.border}></div>
      </div>
    </PublicPageLayout>
  );
};

export default Home;
