import { HashLink } from "@xzar90/react-router-hash-link";
import { Button, Drawer, Layout, Menu, Space } from "antd";
import { gsap } from "gsap";
import {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { CloseIcon, ListIcon } from "../../../common/icons";
import AppContext from "../../context/app-context";
import { DASHBOARD, HOMEPAGE, SIGN_IN, WELCOME } from "../../route-paths";
import { DisplayType } from "../../types/display-type";
import Logo from "../logo/logo";
import * as styles from "./page-header.module.scss";

interface PageHeaderProps {}

const PageHeader: FunctionComponent<PageHeaderProps> = () => {
  const { user, displayType } = useContext(AppContext);
  const [visible, setVisible] = useState(false);

  const onHover = ({ currentTarget }: any) => {
    gsap.to(currentTarget, {
      skew: 50,
      scale: 1.05,
      y: 5,
      duration: 0.1,
      ease: "power4.easeOut",
    });
  };

  const shouldFold = useMemo(
    () =>
      displayType === DisplayType.TABLET || displayType === DisplayType.PHONE,
    [displayType]
  );
  const location = useLocation();
  const isWelcomePage = location.pathname.includes(WELCOME);
  const menu = useMemo(() => {
    const hash = location.hash.slice(1);
    return (
      <Fragment>
        <Menu
          mode={shouldFold ? "vertical" : "horizontal"}
          defaultSelectedKeys={hash ? [hash] : []}
          className={styles.ul}
        >
          <Menu.Item onMouseEnter={onHover} key="home" className={styles.li}>
            <HashLink
              to={{ pathname: HOMEPAGE, hash: "home" } as any}
              className={styles.link}
            >
              Home
            </HashLink>
          </Menu.Item>
          <Menu.Item key="benefits" className={styles.li}>
            <HashLink
              to={{ pathname: HOMEPAGE, hash: "benefits" } as any}
              className={styles.link}
            >
              Benefits
            </HashLink>
          </Menu.Item>
          <Menu.Item key="features" className={styles.li}>
            <HashLink
              to={{ pathname: HOMEPAGE, hash: "features" } as any}
              className={styles.link}
            >
              Features
            </HashLink>
          </Menu.Item>
          <Menu.Item key="pricing" className={styles.li}>
            <HashLink
              to={{ pathname: HOMEPAGE, hash: "pricing" } as any}
              className={styles.link}
            >
              Pricing
            </HashLink>
          </Menu.Item>
        </Menu>
        {user ? (
          <Button type="primary" className={styles.btn}>
            <Link to={DASHBOARD}>{isWelcomePage ? "Done" : "Dashboard"}</Link>
          </Button>
        ) : (
          <Button type="primary" className={styles.btn}>
            <Link to={SIGN_IN}>{isWelcomePage ? "Done" : "Sign In"}</Link>
          </Button>
        )}
      </Fragment>
    );
  }, [shouldFold, location.hash]);

  useEffect(() => {
    if (user) {
      setVisible(false);
    }
  }, [shouldFold]);
  return (
    <Layout.Header
      className={`${styles.header} ${shouldFold ? styles.folded : ""}`}
    >
      <Space direction="horizontal" className={styles.content}>
        <Logo url={HOMEPAGE} />
        {shouldFold ? (
          <Drawer
            placement="left"
            closable
            onClose={() => setVisible(false)}
            closeIcon={<CloseIcon />}
            visible={visible}
            width={"100vw"}
            className={styles.drawer}
            title={
              <Logo className={styles.logo} url={HOMEPAGE} theme="light" />
            }
          >
            {menu}
          </Drawer>
        ) : (
          menu
        )}
        {shouldFold && (
          <Button
            className={styles.icon}
            type="text"
            icon={<ListIcon />}
            onClick={() => {
              setVisible(true);
              console.log("click");
            }}
          />
        )}
      </Space>
    </Layout.Header>
  );
};

export default PageHeader;
