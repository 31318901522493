import { parse } from "querystring";
import { FunctionComponent, useContext, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ProtectionLevel } from "../common/types/protection-level";
import AppContext from "./context/app-context";
import { DASHBOARD, SIGN_IN } from "./route-paths";

interface GuardedRouteProps {
  level?: ProtectionLevel;
}

const GuardedRoute: FunctionComponent<GuardedRouteProps> = ({
  level,
  children,
}) => {
  const { user } = useContext(AppContext);
  const location = useLocation();
  const ElementToRender = useMemo(() => {
    const { pathname, search } = location;
    const redirectUrl = encodeURIComponent(`${pathname}${search}`);
    if (level === ProtectionLevel.Private && !user) {
      return <Navigate to={`${SIGN_IN}?redirectTo=${redirectUrl}`} />;
    }
    if (level === ProtectionLevel.PublicOnly && user) {
      let url = DASHBOARD;
      if (search && search.length > 0) {
        const query = parse(search.substring(1));
        if (query?.redirectTo) {
          url = query.redirectTo as string;
        }
      }
      return <Navigate to={url} />;
    }
    return children;
  }, [level, children, user, location]);

  return ElementToRender as any;
};

export default GuardedRoute;
