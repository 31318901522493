import { FunctionComponent } from "react";
import ActionBtn from "../action-btn/action-btn";
import * as styles from "./google-btn.module.scss";

interface GoogleBtnProps {
  label: string;
  busy?: boolean;
  onClick: () => void;
}

const GoogleBtn: FunctionComponent<GoogleBtnProps> = ({
  label,
  busy,
  onClick,
}) => {
  return (
    <ActionBtn
      busy={busy}
      type="default"
      onClick={onClick}
      block
      className={styles.btn}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1027_1528)">
          <path
            d="M23.7663 12.2764C23.7663 11.4607 23.7001 10.6406 23.559 9.83807H12.2402V14.4591H18.722C18.453 15.9494 17.5888 17.2678 16.3233 18.1056V21.1039H20.1903C22.4611 19.0139 23.7663 15.9274 23.7663 12.2764Z"
            fill="#4285F4"
          />
          <path
            d="M12.2401 24.0008C15.4766 24.0008 18.2059 22.9382 20.1945 21.1039L16.3276 18.1055C15.2517 18.8375 13.8627 19.252 12.2445 19.252C9.11388 19.252 6.45946 17.1399 5.50705 14.3003H1.5166V17.3912C3.55371 21.4434 7.7029 24.0008 12.2401 24.0008Z"
            fill="#34A853"
          />
          <path
            d="M5.50277 14.3003C5.00011 12.8099 5.00011 11.1961 5.50277 9.70575V6.61481H1.51674C-0.185266 10.0056 -0.185266 14.0004 1.51674 17.3912L5.50277 14.3003Z"
            fill="#FBBC04"
          />
          <path
            d="M12.2401 4.74966C13.9509 4.7232 15.6044 5.36697 16.8434 6.54867L20.2695 3.12262C18.1001 1.0855 15.2208 -0.034466 12.2401 0.000808666C7.7029 0.000808666 3.55371 2.55822 1.5166 6.61481L5.50264 9.70575C6.45064 6.86173 9.10947 4.74966 12.2401 4.74966Z"
            fill="#EA4335"
          />
        </g>
        <defs>
          <clipPath id="clip0_1027_1528">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>{" "}
      {label}
    </ActionBtn>
  );
};

export default GoogleBtn;
