import "./index.module.scss";

/* eslint-disable-next-line */
export interface WhatsNewProps {}

export function WhatsNew(props: WhatsNewProps) {
  return (
    <div>
      <h1>Welcome to WhatsNew!</h1>
    </div>
  );
}

export default WhatsNew;
