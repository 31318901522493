@import '../../styles/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .inner {
        padding: 88px 64px 44px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $maxWidth;

        @media (max-width: 767px) {
            padding: 10px;
        }

        :global {
            .ant-typography {
                color: $contentContrastSecondary;
            }

        }

        a {
            color: #fff;
            text-decoration: underline !important;
        }
    }
}