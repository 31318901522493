import dayjs from "dayjs";
import localizeFormat from "dayjs/plugin/localizedFormat.js";
import relativeTime from "dayjs/plugin/relativeTime.js";
import utc from "dayjs/plugin/utc.js";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizeFormat);

export default dayjs;
